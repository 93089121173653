import React, { useContext } from "react";
import { ThemeContext } from "context/themeProvider";

/* ICONS */
import DarkIcon1 from "assets/icon-dark-1.png";
import DarkIcon2 from "assets/icon-dark-2.png";

import LightIcon1 from "assets/icon-light-1.png";
import LightIcon2 from "assets/icon-light-2.png";
import ImageHover from "components/imageHover/index";

const ThemeToggle = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);

  return (
    <ImageHover
      onClick={toggleTheme}
      src={theme === "light" ? DarkIcon1: LightIcon1}
      hoverSrc={theme === "light" ? DarkIcon2: LightIcon2}
      className={"block mb-1 sm:inline-block sm:mt-0 mr-1 w-8 pixelImage cursor-pointer"}
    />
  );
};

export default ThemeToggle;
