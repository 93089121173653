/* LIBS */
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

/* CUSTOMS */
import Paginator from "components/paginator";
import { pageSize } from "constants/models";
import { fetchPost } from "lib/fetch";
import Title from "components/title";
import Loader from "components/loader";
import { FriendlyFilterCard } from "components/friendlyFilterCard";

/* CONSTANTS */
import { NewsBackendPath, FriendlySearchBackendPostfix, FriendlyMetadataBackendPostfix } from "constants/routing/backend";
import { requestFormatFilter } from "lib/elastic";
import { ContentFrontendPath, DatabaseFrontendPath, EntryPostfix } from "constants/routing/frontend";
import Card from "components/card/index";
import Markdown from "react-markdown";
import Time from "components/time/index";
import Button from "components/button/index";
import { Link } from "react-router-dom";

/* ICONS */
import { FaArrowRightToBracket as GotoIcon } from "react-icons/fa6";
import Heading from "components/heading/index";
import Tags from "pages/database/tags";

export default function NewsEntry() {
  const [payload, setPayload] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const [sortField] = useState("createdDate");
  const [sortDirection] = useState("desc");
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState([]);

  const [queryString, setQueryString] = useState("");

  useEffect(() => {
    fetchPost(NewsBackendPath + FriendlyMetadataBackendPostfix, null)
      .then((resp) => {
        setMetadata(resp);

        const temp = Array(Object.keys(resp.filters).length).fill(null);

        setFilters(temp);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    setPage(0);
  }, [sortField, sortDirection, filters]);

  useEffect(() => {
    hackRequest();
  }, [page, sortField, sortDirection, filters]);

  const hackRequest = () => {
    if (!metadata) {
      return;
    }

    setIsLoading(true);

    const body = {
      page: page,
      sortField: sortField,
      sortDirection: sortDirection,
      filters: requestFormatFilter(filters),
      queryString: queryString,
    };

    fetchPost(NewsBackendPath + FriendlySearchBackendPostfix, body)
      .then((resp) => {
        setPayload(resp);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const paginationElement = (autoScroll, allowInputChange) => {
    if (!payload) {
      return;
    }

    return (
      <Paginator
        page={page}
        setPage={setPage}
        currentPageSize={payload.entries && payload.entries.length}
        pageWindowSize={pageSize}
        totalEntries={payload && payload.total}
        enableAutoScroll={autoScroll}
        allowInputChange={allowInputChange}
      />
    );
  };

  const relatedUrl = (x) => {
    if (!x.relatedUrl) {
      return;
    }

    return (
      <>
        <div className="text-left">
          <Heading>Related Urls</Heading>
          <div>
            {x.relatedUrl &&
              x.relatedUrl.map((x, i) => {
                return (
                  <li key={`bullet-${x.id}-${i}`}>
                    <a href={x} target="_blank" rel="noreferrer">
                      {x}
                    </a>
                  </li>
                );
              })}
          </div>
        </div>
      </>
    );
  };

  const content = (item) => {
    if (!item.content) {
      return;
    }

    return (
      <>
        {
          item.content.map((x, i) => {
            return (
              <Link
                key={`bullet-${x.contentId}-${i}`}
                className="text-sm underline"
                to={DatabaseFrontendPath + ContentFrontendPath + EntryPostfix + x.contentId}
                title={x.contentTitle}
              >
                <Button className="rounded-lg m-2">
                  <div className="flex items-center justify-center">
                    <GotoIcon className="mr-2" /> Content
                  </div>
                </Button>
              </Link>
            );
          })
        }
      </>
    );
  };

  const images = (x) => {
    if (!x || !x.imageUrl) {
      return;
    }

    return (
      <div className="flex  justify-center  flex-wrap">
        {x.imageUrl.map((y, i) => {
          return <img key={`image-${i}`} className="p-3 max-h-64" src={y} alt="" />;
        })}
      </div>
    );
  };

  return (
    <>
      <Title>News</Title>
      <Loader isLoading={isLoading} />

      <div className="lg:grid lg:grid-cols-12 gap-3">
        {
          <div className="col-span-12">
            <FriendlyFilterCard
              filters={filters}
              setFilters={setFilters}
              setQueryString={setQueryString}
              searchUri={NewsBackendPath}
              onClick={hackRequest}
              metadata={metadata}
            />
          </div>
        }

        <div className="col-span-12">
          <div className="flex items-center justify-end">{paginationElement(false, true)}</div>

          {payload &&
            payload.entries &&
            payload.entries.map((x, i) => {
              return (
                <Card key={`news-${i}`} className="m-2" title={x.title}>
                  <div className="flex items-center justify-center italic text-sm">
                    <div className="m-1">Published on:</div>
                    <Time time={x.createdDate} />
                  </div>
                  {images(x)}
                  <Markdown className={"text-contrastChangeText text-left"}>{x.text}</Markdown>

                  <div className="grid grid-cols-12 gap-1 w-full">
                    <div  className="col-span-7">{relatedUrl(x)}</div>
                    <div className="col-span-5">{<Tags payload={x}/>}</div>
                  </div>

                  <div className="flex items-center justify-end">
                    {content(x)}
                  </div>
                </Card>
              );
            })}
          <div className="flex items-center justify-end">{paginationElement(true, false)}</div>
        </div>
      </div>
    </>
  );
}
