/* LIBS */
import React, { useState } from "react";
import { toast } from "react-toastify";
import Button from "components/button";
import { useLocation, useNavigate } from "react-router-dom";

/* CUSTOMS */
import TextArea from "components/textArea";
import Card from "components/card";
import Title from "components/title";
import { fetchPost } from "lib/fetch";
import Loader from "components/loader";

/* CONSTANTS */
import {
  QueueBackendPath,
} from "constants/routing/backend";
import {
  DeletePostfix,
  EntryPostfix,
  ListPostfix,
  SubmissionQueueFrontendPath,
} from "constants/routing/frontend";

export default function DeleteSubmission() {
  const [isLoading, setIsLoading] = useState(false);

  const [historyComment, setHistoryComment] = useState("");

  // Function to parse query parameters
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queueId = queryParams.get("queueId");
  const entityQueryId = queryParams.get("entityId");
  const entityType = queryParams.get("entityType");

  const navigate = useNavigate();

  const validationCheck = () => {
    var pass = true;

    if (queueId) {
      toast.error("cannot edit deletion request");
      pass = false;
    }

    if (historyComment === "") {
      toast.error("history comment must be filled out.");
      pass = false;
    }

    return pass;
  };

  const queueSubmitRequest = () => {
    if (!validationCheck()) {
      return;
    }

    const params = {
      entityId: entityQueryId,
      entityType: entityType,
      historyComment: historyComment,
    };

    setIsLoading(true);

    fetchPost(QueueBackendPath + DeletePostfix, params)
      .then(() => {
        toast.success("Entry created.");
        setIsLoading(false);
        if (queueId) {
          navigate(SubmissionQueueFrontendPath + EntryPostfix + queueId);
        } else {
          navigate(SubmissionQueueFrontendPath + ListPostfix);
        }
      })
      .catch((resp) => {
        toast.error(resp.message);
      });
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      <Title className={"col-span-6"}>{`Delete ${entityType} Request`}</Title>

      <Card className="m-8" title={"History Comment"}>
        <div className="w-full text-start">
          <TextArea
            placeholder="Comment"
            noResize={true}
            wrapperClassName="w-full"
            className={"h-20"}
            value={historyComment}
            onChange={setHistoryComment}
          />
        </div>
      </Card>

      <div className="flex justify-end mr-5">
        <Button className={"mr-3 ml-3 mb-3 mt-3 overflow-hidden rounded-b-md rounded-t-md"} onClick={queueSubmitRequest}>
          Submit
        </Button>
      </div>
    </>
  );
}
