/* LIBS */
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

/* CUSTOMS */
import Paginator from "components/paginator";
import { pageSize } from "constants/models";
import { fetchPost } from "lib/fetch";
import Title from "components/title";
import Loader from "components/loader";
import { FriendlyTable } from "components/friendlyTable";
import { FriendlyFilterCard } from "components/friendlyFilterCard";

/* CONSTANTS */
import { ContentBackendPath, FriendlySearchBackendPostfix, FriendlyMetadataBackendPostfix } from "constants/routing/backend";
import { filterOperatorMust, requestFormatFilter } from "lib/elastic";
import { AuthorFrontendPath, ContentFrontendPath, DatabaseFrontendPath, EntryPostfix, GamesFrontendPath } from "constants/routing/frontend";

export default function HackGridView() {
  const [payload, setPayload] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const [sortField] = useState("releaseDate");
  const [sortDirection] = useState("desc");
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState([]);

  const [queryString, setQueryString] = useState("");

  const columns = [
    {
      label: "Original Game",
      idField: "gameId",
      field: "gameTitle",
      width: "",
      dataType: "link",
      to: DatabaseFrontendPath + GamesFrontendPath + EntryPostfix,
    },
    {
      label: "Platform",
      field: "platform",
      width: "",
      dataType: "write",
    },
    {
      label: "Released By",
      idField: "authorId",
      field: "authorName",
      width: "",
      dataType: "link",
      to: DatabaseFrontendPath + AuthorFrontendPath + EntryPostfix,
    },
    {
      label: "Downloads",
      field: "downloadCount",
      width: "",
      dataType: "write",
    },
    {
      label: "Tags",
      field: "tags",
      width: "col-span-12",
      dataType: "pills",
    },
  ];

  useEffect(() => {
    fetchPost(ContentBackendPath + FriendlyMetadataBackendPostfix, null)
      .then((resp) => {
        setMetadata(resp);

        const temp = Array(Object.keys(resp.filters).length).fill(null);
        temp.push({
          field: "tags",
          operator: filterOperatorMust,
          value: "Hack",
        });

        setFilters(temp);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    setPage(0);
  }, [sortField, sortDirection, filters]);

  useEffect(() => {
    hackRequest();
  }, [page, sortField, sortDirection, filters]);

  const hackRequest = () => {
    if (!metadata){
      return;
    }

    setIsLoading(true);

    const body = {
      page: page,
      sortField: sortField,
      sortDirection: sortDirection,
      filters: requestFormatFilter(filters),
      queryString: queryString,
    };

    fetchPost(ContentBackendPath + FriendlySearchBackendPostfix, body)
      .then((resp) => {
        setPayload(resp);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const paginationElement = (autoScroll, allowInputChange) => {
    if (!payload) {
      return;
    }

    return (
      <Paginator
        page={page}
        setPage={setPage}
        currentPageSize={payload.entries && payload.entries.length}
        pageWindowSize={pageSize}
        totalEntries={payload && payload.total}
        enableAutoScroll={autoScroll}
        allowInputChange={allowInputChange}
      />
    );
  };

  return (
    <>
      <Title>Hacks</Title>
      <Loader isLoading={isLoading} />

      <div className="lg:grid lg:grid-cols-12 gap-3">
        <div className="col-span-12">
          <FriendlyFilterCard
            filters={filters}
            setFilters={setFilters}
            setQueryString={setQueryString}
            searchUri={ContentBackendPath}
            onClick={hackRequest}
            metadata={metadata}
          />
        </div>

        <div className="col-span-12">
          <div className="flex items-center justify-end">{paginationElement(false, true)}</div>
          <FriendlyTable
            data={payload && payload.entries}
            titleKey="title"
            thumbnailKey="thumbnailUrl"
            preambleKey="description"
            gotoIdPath={DatabaseFrontendPath + ContentFrontendPath + EntryPostfix}
            columns={columns}
          />
          <div className="flex items-center justify-end">{paginationElement(true, false)}</div>
        </div>
      </div>
    </>
  );
}
