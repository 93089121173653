/** LIBS */
import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Markdown from "react-markdown";
import { Link } from "react-router-dom";

/* CUSTOMS */
import Card from "components/card";
import { fetchPost } from "lib/fetch";
import Title from "components/title";
import Loader from "components/loader";
import SeoHeader from "components/seoHeader";
import Tags from "../tags";
import DeletionNoticeCard from "../deletionNotice";

/* CONSTANTS */
import { GetBackendPostfix, NewsBackendPath } from "constants/routing/backend";
import { removeMarkdownFormatting } from "lib/strings";
import HistoryCard from "pages/history";
import Button from "components/button/index";
import {
  ContentFrontendPath,
  DatabaseFrontendPath,
  EntryPostfix,
  NewsFrontendPath,
} from "constants/routing/frontend";

/* ICONS */
import { FaArrowRightToBracket as GotoIcon } from "react-icons/fa6";

/* SERVICES */
import { UserContext } from "context/user";
import QueueManageButtons from "../queueManageButton";

export default function NewsEntry() {
  const [payloadNews, setPayloadNews] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { id } = useParams();

  const margin = "ml-1 mb-3";

  const { userContext } = useContext(UserContext);
  const { username /*, permissions*/ } = userContext;

  useEffect(() => {
    hackGetRequest();
  }, []);

  const hackGetRequest = () => {
    setIsLoading(true);

    const body = {
      id: id,
    };

    fetchPost(NewsBackendPath + GetBackendPostfix, body)
      .then((resp) => {
        setPayloadNews(resp);
      })
      .catch((resp) => {
        toast.error(resp.message);
        setPayloadNews(null);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const newsText = () => {
    if (!payloadNews || !payloadNews.text) {
      return;
    }

    return (
      <Card className={margin} title={"News Description"}>
        <div className="max-h-80 overflow-y-auto">
          <Markdown className="text-left mr-3">{payloadNews && payloadNews.text}</Markdown>
        </div>
      </Card>
    );
  };

  const linksCard = () => {
    if (!payloadNews) {
      return;
    }

    const legacyId = () => {
      if (!payloadNews.legacyId) {
        return;
      }

      const legacyId = payloadNews.legacyId;
      return (
        <div>
          <h3 className="text-left">Legacy Id</h3>
          <ol>
            <li className="text-left">
              <a href={`https://www.romhacking.net/${legacyId}`}>{legacyId}</a>
            </li>
          </ol>
        </div>
      );
    };

    const content = () => {
      if (!payloadNews.contentId) {
        return;
      }

      return (
        <>
          {payloadNews.contentId.map((x, i) => {
            return (
              <div key={`bullet-${x.id}-${i}`} className="flex items-end justify-end">
                <Link
                  
                  className="text-sm underline"
                  to={DatabaseFrontendPath + ContentFrontendPath + EntryPostfix + x}
                >
                  <Button className="rounded-lg m-2">
                    <div className="flex items-center justify-center">
                      <GotoIcon className="mr-2" /> Content
                    </div>
                  </Button>
                </Link>
              </div>
            );
          })}
        </>
      );
    };

    return (
      <Card className={margin} title={"Details"}>
        <div>{<Tags payload={payloadNews}/>}</div>
        <div>{content()}</div>
        <div>{legacyId()}</div>
      </Card>
    );
  };

  const imagesCard = () => {
    if (!payloadNews) {
      return;
    }

    if (!payloadNews.imageUrl) {
      return;
    }

    const padding = "p-1";
    return (
      <Card className={margin} title={"Images"}>
        <div className="container h-auto mb-4">
          <div className="grid grid-cols-4 h-auto">
            {payloadNews.imageUrl.map((c, i) => {
              return <img key={i} className={padding} src={c} alt="" />;
            })}
          </div>
        </div>
      </Card>
    );
  };

  const cardClass = "lg:w-1/2 w-full";

  return (
    <>
      {payloadNews ? (
        <SeoHeader pageTitle={"News"} pageSectionTitle={payloadNews.title} description={removeMarkdownFormatting(payloadNews.text)} />
      ) : null}

      <Title>News Entry</Title>

      {payloadNews ? (
        <QueueManageButtons id={id} frontendPath={NewsFrontendPath} entityType={"news"} isDeleted={payloadNews.isDeleted}/>):null}

      <Loader isLoading={isLoading} />
      <div className="lg:flex">
        <div className={`${cardClass} mr-2`}>
          {linksCard()}
          {imagesCard()}
          {<HistoryCard payload={payloadNews} />}
        </div>
        <div className={`${cardClass} ${margin}`}>
          {<DeletionNoticeCard payload={payloadNews}/>}
          {newsText()}
        </div>
      </div>
    </>
  );
}
