/* LIBS */
import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import Markdown from "react-markdown";
import Button from "components/button";
import { useLocation, useNavigate } from "react-router-dom";

/* CUSTOMS */
import MultiSelectAutocomplete2 from "components/multiSelectAutocomplete2";
import MultiSelect from "components/multiSelect";
import TextArea from "components/textArea";
import TextBox from "components/textBox";
import Card from "components/card";
import Heading from "components/heading";
import Title from "components/title";
import { fetchPost } from "lib/fetch";
import { getDatInfo, generateHashMessage } from "lib/hasher-js-2";
import readFile from "lib/file";
import Loader from "components/loader";
import Calendar from "components/calendar";
import DropzoneComponent, { FileInformation } from "components/dropZone";
import { ToLabelValueFromMap, ToLabelValueFromSource } from "lib/strings";
import { Table } from "components/table";
import { allowedImageType, allowedArchiveType, allowedReadmeType, allowedRomType, newId } from "constants/models";
import CreditModal from "../../creditModal";
import { DateTimeToEpochSeconds, EpochSecondsToDateTime } from "lib/time";
import { downloadFileToArrayBuffer, extractBucketAndKey } from "lib/downloadUrl";

/* ICON */
import { FaPlus as AddFilterIcon } from "react-icons/fa";
import { FaPencilAlt as EditIcon } from "react-icons/fa";
import { FaRegTrashAlt as DeleteIcon } from "react-icons/fa";

/* CONSTANTS */
import {
  HashBackendPath,
  GetBackendPostfix,
  AuthorBackendPath,
  FieldValuesBackendPostfix,
  ByPlatformBackendPostfix,
  GameBackendPath,
  PlatformBackendPath,
  SearchBackendPostfix,
  ContentBackendPath,
  FieldsValuesBackendPostfix,
  QueueBackendPostfix,
  CreateBackendPostfix,
  QueueBackendPath,
  UpdateBackendPostfix,
} from "constants/routing/backend";

import { EntryPostfix, ListPostfix, SubmissionQueueFrontendPath } from "constants/routing/frontend";

export default function ContentSubmission() {
  const [isLoading, setIsLoading] = useState(false);

  const [contentTitle, setContentTitle] = useState("");
  const [description, setDescription] = useState("");
  const [datText, setDatText] = useState("");
  const [gameId, setGameId] = useState("");
  const [gameTitle, setGameTitle] = useState("");

  const [authorId, setAuthorId] = useState("");
  const [authorName, setAuthorName] = useState("");

  const [platform, setPlatform] = useState("");
  const [platformServerList, setPlatformServerList] = useState({});

  const [suppressGameBlanking, setSuppressGameBlanking] = useState(false);

  const [tags, setTags] = useState([]);
  const [releaseDate, setReleaseData] = useState(new Date());
  const [versionText, setVersionText] = useState("");
  const [fileArchive, setFileArchive] = useState([]);
  const [fileReadme, setFileReadme] = useState([]);
  const [fileTitleScreen, setFileTitleScreen] = useState([]);
  const [fileScreenshot, setFileScreenshot] = useState([]);
  const [gameTitleScreen, setGameTitleScreen] = useState([]);
  const [gameDescription, setGameDescription] = useState("");
  const [creditModalOpen, setCreditModalOpen] = useState(false);
  const [actionType, setActionType] = useState("");
  const [filterIndex, setFilterIndex] = useState(0);
  const [creditList, setCreditList] = useState([]);
  const [videoUrl, setVideoUrl] = useState("");
  const [license, setLicense] = useState("");
  const [sourceCodeUrl, setSourceCodeUrl] = useState("");
  const [isRendering, setIsRendering] = useState(true);

  const [entityId, setEntityId] = useState("");

  const [isNewGame, setIsNewGame] = useState(false);

  const [historyComment, setHistoryComment] = useState("");

  // Function to parse query parameters
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queueId = queryParams.get("queueId");
  const entityQueryId = queryParams.get("entityId");

  const navigate = useNavigate();

  useEffect(() => {
    if (entityQueryId && queueId) {
      toast.error("Cannot edit both queue and hack");
      return;
    }

    if (entityQueryId) {
      contentRequest(entityQueryId);
    }

    if (queueId) {
      queueRequest(queueId);
    }

    platformRequest();

    setIsRendering(false);
  }, []);

  useEffect(() => {
    setEntityId(entityQueryId);
  }, [entityQueryId]);

  useEffect(() => {
    if (isRendering) {
      return;
    }

    // Prevent blanking game when loading in data in from server
    if (suppressGameBlanking) {
      setSuppressGameBlanking(false);
      return;
    }
    gameMultiSelectRef.current.clearInput();
  }, [platform]);

  const gameMultiSelectRef = useRef(null);

  const markdownWidth = "md:col-span-3 col-span-6";

  const validationCheck = () => {
    var pass = true;
    if (gameId === ""){
      toast.error("Must select a game");
      pass = false;
    }

    if (authorId === ""){
      toast.error("Must select an author");
      pass = false;
    }

    if (!tags || tags.length === 0 ){
      toast.error("Must select at least one tag");
      pass = false;
    }

    if (description === ""){
      toast.error("Description must be filled out.");
      pass = false;
    }

    if (datText === ""){
      toast.error("Must enter dat information.");
      pass = false;
    }

    if (contentTitle === ""){
      toast.error("Must enter a title.");
      pass = false;
    }

    if (Object.keys(fileTitleScreen).length > 1){
      toast.error("Cannot have more than one thumbnail.");
      pass = false;
    }

    if ( Object.keys(fileScreenshot).length > 4){
      toast.error("The screenshot limit is 4.");
      pass = false;
    }

    if (Object.keys(fileArchive).length !== 1){
      toast.error("Please upload an archive");
      pass = false;
    }

    return pass;
  };

  const queueSubmitRequest = () => {
    if (!validationCheck()){
      return;
    }

    const temp = [];
    fileScreenshot.forEach((x) => {
      temp.push(x.ConvertToRequest());
    });

    const isUpdate = queueId !== null;

    var gameThumbnail = null;

    if (Object.keys(gameTitleScreen).length > 0){
      gameThumbnail = gameTitleScreen[0].ConvertToRequest();
    }

    var gameInfo = null;
    if (isNewGame){
      gameInfo ={
        gameId: gameId,
        isNew: isNewGame,

        title: gameTitle,
        thumbnail: gameThumbnail,
      };
    } else {
      gameInfo ={
        gameId: gameId,
        title: gameTitle,
        isNew: isNewGame,
      };
    }

    const params = {
      queueId: queueId,
      entityId: entityId,

      title: contentTitle,
      tags: tags,
      gameInfo: gameInfo,
      authorId: authorId,
      authorName: authorName,
      platform: platform,
      releaseDate: DateTimeToEpochSeconds(releaseDate),
      description: description,
      version: versionText,
      dat: datText,
      file: fileArchive.length > 0 ? fileArchive[0].ConvertToRequest() : null,
      readme: fileReadme.length > 0 ? fileReadme[0].ConvertToRequest() : null,
      thumbnail: fileTitleScreen.length > 0 ? fileTitleScreen[0].ConvertToRequest() : null,
      images: temp,
      videoUrl: videoUrl,
      credits: creditList,
      license: license,
      sourceCodeUrl: sourceCodeUrl,
      historyComment: historyComment,
    };

    const postfix = isUpdate ? UpdateBackendPostfix : CreateBackendPostfix;

    setIsLoading(true);
    fetchPost(ContentBackendPath + QueueBackendPostfix + postfix, params)
      .then(() => {
        toast.success(`Entry ${isUpdate ? "updated" : "created"}.`);
        if (queueId){
          navigate(SubmissionQueueFrontendPath + EntryPostfix + queueId);
        } else {
          navigate(SubmissionQueueFrontendPath + ListPostfix);
        }
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally( () =>{
        setIsLoading(false);
      });
  };

  const contentRequest = (id) => {
    const body = {
      id: id,
    };

    setIsLoading(true);

    fetchPost(ContentBackendPath + GetBackendPostfix, body)
      .then((d) => {
        setContentTitle(d.title);
        setDescription(d.description);
        setTags(d.tags);
        setPlatform(d.platform);

        setAuthorId(d.authorId);
        setAuthorName(d.authorName);

        setGameId(d.gameId);
        setGameTitle(d.gameTitle);

        setCreditList(d.credits);

        setVersionText(d.version);
        setDatText(d.dat);
        setReleaseData(EpochSecondsToDateTime(d.releaseDate));

        setSourceCodeUrl(d.sourceCodeUrl);
        setLicense(d.license);
        setVideoUrl(d.videoUrl);

        setSuppressGameBlanking(true);

        if (d.thumbnailUrl){

          downloadFileToArrayBuffer(d.thumbnailUrl)
            .then((bytes) => {
              const { key, keyPostfix } = extractBucketAndKey(d.thumbnailUrl);
              setFileTitleScreen([new FileInformation(key, bytes, keyPostfix, false)]);
            })
            .catch((resp) => {
              toast.error(resp.message);
            });
        }

        if (d.readmeUrl) {
          const { key, keyPostfix } = extractBucketAndKey(d.readmeUrl);
          setFileReadme([new FileInformation(key, new Uint8Array(0), keyPostfix, false)]);
        }

        if (d.fileUrl) {
          const { key, keyPostfix } = extractBucketAndKey(d.fileUrl);
          setFileArchive([new FileInformation(key, new Uint8Array(0), keyPostfix, false)]);
        }

        const downloadPromises = d.imageUrl && d.imageUrl.map((x) => {
          return downloadFileToArrayBuffer(x)
            .then((bytes) => {
              const { key } = extractBucketAndKey(x);
              return new FileInformation(key, bytes, key, false);
            })
            .catch((error) => {
              toast.error(error.message);
              return null; 
            });
        });
      
        // Use Promise.all to wait for all download promises to complete
        downloadPromises && Promise.all(downloadPromises)
          .then((screenshots) => {
            const validScreenshots = screenshots.filter(s => s !== null);
            setFileScreenshot(validScreenshots);
          })
          .catch((error) => {
            toast.error(error.message);
          });
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally( () =>{
        setIsLoading(false);
      });
  };

  const queueRequest = (id) => {
    const body = {
      id: id,
    };

    setIsLoading(true);

    fetchPost(QueueBackendPath + GetBackendPostfix, body)
      .then((e) => {
        setEntityId(e.queueEntry.entityId);

        const d = JSON.parse(e.queueEntry.data);
        setContentTitle(d.title);
        setDescription(d.description);
        setTags(d.tags);
        setPlatform(d.platform);

        setAuthorId(d.authorId);
        setAuthorName(d.authorName);

        setGameId(d.gameInfo.gameId);
        setGameTitle(d.gameInfo.title);
        setIsNewGame(d.gameInfo.isNew);

        setCreditList(d.credits);

        setVersionText(d.version);
        setDatText(d.dat);
        setReleaseData(EpochSecondsToDateTime(d.releaseDate));

        setSourceCodeUrl(d.sourceCodeUrl);
        setLicense(d.license);
        setVideoUrl(d.videoUrl);

        setHistoryComment(e.queueEntry.historyComment);

        setSuppressGameBlanking(true);

        if (d.thumbnailUrl){
          downloadFileToArrayBuffer(d.thumbnailUrl)
            .then((bytes) => {
              const { key, keyPostfix } = extractBucketAndKey(d.thumbnailUrl);
              setFileTitleScreen([new FileInformation(key, bytes, keyPostfix, false)]);
            })
            .catch((resp) => {
              toast.error(resp.message);
            });
        }

        if (d.gameInfo.thumbnailUrl){
          downloadFileToArrayBuffer(d.gameInfo.thumbnailUrl)
            .then((bytes) => {
              const { key, keyPostfix } = extractBucketAndKey(d.gameInfo.thumbnailUrl);
              setGameTitleScreen([new FileInformation(key, bytes, keyPostfix, false)]);
            })
            .catch((resp) => {
              toast.error(resp.message);
            });
        }

        if (d.readmeUrl) {
          downloadFileToArrayBuffer(d.readmeUrl)
            .then((bytes) => {
              const { key, keyPostfix } = extractBucketAndKey(d.readmeUrl);
              setFileReadme([new FileInformation(key, bytes, keyPostfix, false)]);
            })
            .catch((resp) => {
              toast.error(resp.message);
            });
        }

        if (d.fileUrl) {
          downloadFileToArrayBuffer(d.fileUrl)
            .then((bytes) => {
              const { key, keyPostfix } = extractBucketAndKey(d.fileUrl);
              setFileArchive([new FileInformation(key, bytes, keyPostfix, false)]);
            })
            .catch((resp) => {
              toast.error(resp.message);
            });
        }

        const downloadPromises = d.imageUrl && d.imageUrl.map((x) => {
          return downloadFileToArrayBuffer(x)
            .then((bytes) => {
              const { key, keyPostfix } = extractBucketAndKey(x);
              return new FileInformation(key, bytes, keyPostfix, false);
            })
            .catch((error) => {
              toast.error(error.message);
              return null; 
            });
        });
      
        // Use Promise.all to wait for all download promises to complete
        downloadPromises && Promise.all(downloadPromises)
          .then((screenshots) => {
            const validScreenshots = screenshots.filter(s => s !== null);
            setFileScreenshot(validScreenshots);
          })
          .catch((error) => {
            toast.error(error.message);
          });
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally( () =>{
        setIsLoading(false);
      });
  };

  const platformRequest = () => {
    setIsLoading(true);
    fetchPost(PlatformBackendPath + SearchBackendPostfix, null)
      .then((resp) => {
        setPlatformServerList(resp);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally( () =>{
        setIsLoading(false);
      });
  };

  const generateDatInfo = (acceptedFiles) => {
    if (!platform) {
      return;
    }

    setIsLoading(true);

    acceptedFiles.forEach(async (file) => {
      readFile(file)
        .then((x) => {
          try {
            var dats = getDatInfo(new Uint8Array(x), platform);
          } catch (e) {
            console.log(e);
          }

          var body = { sha1Hash: dats.rom.sha1Hash, system: platform };

          setIsLoading(true);
          fetchPost(HashBackendPath + GetBackendPostfix, body)
            .then((resp) => {
              const { error, message } = generateHashMessage(resp, file, dats);

              if (error) {
                toast.error(error);
              }

              if (message) {
                setDatText(message);
              }
            })
            .catch((e) => {
              toast.error(e.message);
            })
            .finally( () =>{
              setIsLoading(false);
            });
        })
        .catch((error) => {
          toast.error(error);
        });
    });

    setIsLoading(false);
  };

  const CardCredits = () => {
    const headerColClass = "px-6 py-3 text-left tracking-wider";
    const rowColClass = "px-6 py-3 text-left truncate";

    return (
      <div className="m-8">
        <Heading>Credits</Heading>
        <CreditModal
          actionType={actionType}
          modalOpen={creditModalOpen}
          setModalOpen={setCreditModalOpen}
          creditIndex={filterIndex}
          setCreditIndex={setFilterIndex}
          creditList={creditList}
          setCreditList={setCreditList}
        />

        <Table>
          <thead>
            <tr>
              <th className={headerColClass}>Author Name</th>
              <th className={headerColClass}>Type</th>
              <th className={headerColClass}>Comment</th>
              <th className={headerColClass}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {creditList &&
              creditList.map((d, i) => {
                return (
                  <tr key={`Tbody-${i}`}>
                    <td className={rowColClass}>{d.creditName}</td>
                    <td className={rowColClass}>{d.type}</td>
                    <td className={rowColClass}>{d.comment}</td>
                    <td className={rowColClass}>
                      <div className="flex space-x-2">
                        <Button
                          className={"rounded-md"}
                          onClick={() => {
                            setFilterIndex(i);
                            setActionType("Edit");
                            setCreditModalOpen(true);
                          }}
                        >
                          <EditIcon />
                        </Button>
                        <Button
                          secondary
                          className={"rounded-md"}
                          onClick={() => {
                            const temp = [...creditList];
                            temp.splice(i, 1);
                            setCreditList(temp);
                          }}
                        >
                          <DeleteIcon />
                        </Button>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>

        <div className="flex justify-end mr-5">
          <Button
            className={"col-span-2 mr-3 ml-3 mb-3 mt-3 overflow-hidden rounded-b-md rounded-t-md"}
            onClick={() => {
              setFilterIndex(-1);
              setActionType("Add");
              setCreditModalOpen(true);
            }}
          >
            <AddFilterIcon />
          </Button>
        </div>

      </div>
    );
  };

  const clearGame = () => {
    setGameId("");
    setGameTitle("");
    setIsNewGame(false);
    setGameDescription("");
    setGameTitleScreen([]);
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      <Title className={"col-span-6"}>{queueId || entityId ? "Edit Content" : "Submit Content"}</Title>
      <Card className="m-8" title={"General Information"}>
        <div className="grid grid-cols-6 gap-4 text-start">
          <TextBox
            value={contentTitle}
            id={"content-title-textBox"}
            className={"max-lg:col-span-6 md:col-span-2 w-full"}
            placeholder={"Content Title"}
            onChange={setContentTitle}
          />

          <MultiSelectAutocomplete2
            className="max-lg:col-span-6 md:col-span-4"
            placeholder="Tags"
            field="tags"
            searchUri={ContentBackendPath}
            searchPostfix={FieldValuesBackendPostfix}
            label={tags}
            isMulti={true}
            setLabel={setTags}
          />

          <MultiSelect
            className="max-lg:col-span-6 md:col-span-3"
            placeholder="Platform"
            isMulti={false}
            onSelect={(e) => {
              setPlatform(e.value);
              clearGame();
            }}
            onClear={() => {
              setPlatform("");
              clearGame();
            }}
            options={ToLabelValueFromMap(platformServerList)}
            value={ToLabelValueFromSource(platform, ToLabelValueFromMap(platformServerList))}
            onInputChange={() => {}}
            onChange={() => {}}
          />

          <MultiSelectAutocomplete2
            className="max-lg:col-span-6 md:col-span-3"
            placeholder="Game"
            field="title"
            ref={gameMultiSelectRef}
            searchUri={GameBackendPath}
            searchPostfix={FieldValuesBackendPostfix + ByPlatformBackendPostfix}
            isMulti={false}
            isCreatable={true}
            setValue={(x) => {
              setGameId(x);
              setIsNewGame(x === newId);
            }}
            setLabel={setGameTitle}
            value={[gameId]}
            label={[gameTitle]}
            disabled={platform.length === 0}
            customSearchParams={(autoCompleteText) => {
              const params = {
                field: "title",
                value: autoCompleteText,
                platform: platform,
              };

              return params;
            }}
          />

          <MultiSelectAutocomplete2
            className="max-lg:col-span-6 md:col-span-3"
            placeholder="Author"
            field="title"
            searchUri={AuthorBackendPath}
            searchPostfix={FieldsValuesBackendPostfix}
            isMulti={false}
            setLabel={setAuthorName}
            setValue={setAuthorId}
            value={[authorId]}
            label={[authorName]}
          />
        </div>
      </Card>

      {isNewGame && (
        <Card className="m-8" title={"Game (New Entry)"}>
          <div className="grid grid-cols-6 gap-4 text-start">
            <div className="col-span-6">
              <Heading>Title Screen Image</Heading>
              <DropzoneComponent files={gameTitleScreen} setFiles={setGameTitleScreen} allowedFileTypes={allowedImageType} />
            </div>

            <div className={markdownWidth}>
              <Heading>Markdown</Heading>
              <TextArea
                noResize={true}
                wrapperClassName="w-full"
                className={"h-80"}
                value={gameDescription}
                onChange={(value) => {
                  setGameDescription(value);
                }}
              />
            </div>
            <div className={markdownWidth}>
              <Heading>Preview</Heading>
              <div className="col-span-3 overflow-scroll h-80">
                <Markdown className="w-full">{gameDescription}</Markdown>
              </div>
            </div>
          </div>
        </Card>
      )}

      <Card className="m-8" title={"File Information"}>
        <div className="grid grid-cols-6 gap-4 text-start">
          <TextBox
            value={versionText}
            id={"version-textBox"}
            className={"max-lg:col-span-6 md:col-span-3 w-full"}
            placeholder={"Version"}
            onChange={setVersionText}
          />
          <Calendar
            id={"calendar"}
            className={"max-lg:col-span-6 md:col-span-3 w-full"}
            placeholder={"Release date"}
            startDate={releaseDate}
            onChange={setReleaseData}
            displayUTC
          />
        </div>
      </Card>

      <Card className="m-8" title={"File Upload"}>
        <div className="grid grid-cols-6 gap-4 text-start">
          <div className={markdownWidth}>
            <Heading>File Archive</Heading>
            <DropzoneComponent files={fileArchive} setFiles={setFileArchive} allowedFileTypes={allowedArchiveType} allowMultiple={false} />
          </div>

          <div className={markdownWidth}>
            <Heading>Readme</Heading>
            <DropzoneComponent files={fileReadme} setFiles={setFileReadme} allowedFileTypes={allowedReadmeType} />
          </div>

          <div className={markdownWidth}>
            <Heading>Thumbnail Image</Heading>
            <DropzoneComponent files={fileTitleScreen} setFiles={setFileTitleScreen} allowedFileTypes={allowedImageType} />
          </div>
          <div className={markdownWidth}>
            <Heading>Screenshots</Heading>
            <DropzoneComponent
              files={fileScreenshot}
              setFiles={setFileScreenshot}
              allowedFileTypes={allowedImageType}
              allowMultiple
              fileLimit={4}
            />
          </div>
        </div>
      </Card>
      <Card className="m-8" title={"Description"}>
        <div className="grid grid-cols-6 gap-4 text-start">
          <div className={markdownWidth}>
            <Heading>Markdown</Heading>
            <TextArea
              noResize={true}
              wrapperClassName="w-full"
              className={"h-80"}
              value={description}
              onChange={(value) => {
                setDescription(value);
              }}
            />
          </div>
          <div className={markdownWidth}>
            <Heading>Preview</Heading>
            <div className="col-span-3 overflow-scroll h-80">
              <Markdown className="w-full">{description}</Markdown>
            </div>
          </div>
        </div>
      </Card>
      <Card className="m-8" title={"ETC"}>
        <div className="grid grid-cols-6 gap-4 text-start">
          <TextBox
            value={sourceCodeUrl}
            id={"source-code-textBox"}
            className={"max-lg:col-span-6 md:col-span-3 w-full"}
            placeholder={"Source Code Url"}
            onChange={setSourceCodeUrl}
          />
          <MultiSelectAutocomplete2
            className="max-lg:col-span-6 md:col-span-3"
            placeholder="License"
            field="license"
            searchUri={ContentBackendPath}
            searchPostfix={FieldValuesBackendPostfix}
            isMulti={false}
            setLabel={setLicense}
            label={[license]}
          />
          <TextBox
            value={videoUrl}
            id={"video-textBox"}
            className={"max-lg:col-span-6 md:col-span-3 w-full"}
            placeholder={"Video Url"}
            onChange={setVideoUrl}
          />
        </div>
      </Card>
      <Card className="m-8" title={"DAT Information"}>
        <div className="grid grid-cols-6 gap-4 text-start">
          <DropzoneComponent
            className={`${markdownWidth} w-full`}
            onDropAction={generateDatInfo}
            isDisabled={!platform}
            allowedFileTypes={allowedRomType}
            allowMultiple={false}
          />
        </div>
        <div className="grid grid-cols-6 gap-4 text-start">
          <div className={`${markdownWidth} w-full`}>
            <Heading>Markdown</Heading>
            <TextArea
              noResize={true}
              wrapperClassName="w-full col-span-6"
              className={"h-60"}
              value={datText}
              onChange={(value) => {
                setDatText(value);
              }}
            />
          </div>
          <div className={`${markdownWidth} w-full`}>
            <Heading>Preview</Heading>
            <div className={`${markdownWidth} overflow-scroll w-full`}>
              <Markdown className={"w-full h-60"}>{datText}</Markdown>
            </div>
          </div>
        </div>
      </Card>

      <CardCredits />

      <Card className="m-8" title={"History Comment"}>
        <div className="w-full text-start">
          <TextArea
            placeholder="Comment"
            noResize={true}
            wrapperClassName="w-full"
            className={"h-20"}
            value={historyComment}
            onChange={setHistoryComment}
          />
        </div>
      </Card>

      <div className="flex justify-end mr-5">
        <Button
          className={"mr-3 ml-3 mb-3 mt-3 overflow-hidden rounded-b-md rounded-t-md"}
          onClick={queueSubmitRequest}
        >
          Submit
        </Button>
      </div>
    </>
  );
}
