/** LIBS */
import React from "react";
import { Route, Routes } from "react-router-dom";

/* CUSTOMS */
import ButtonLinksTabbed from "components/buttonLinksTabbed";
import { ResourceGridView } from "../search/resource";
import { HackGridView } from "../search/hacks";
import { GameGridView } from "../search/game";

import { ContentEntry } from "../database/content";
import { GameEntry } from "../database/game";
import { AuthorEntry } from "../database/author";
import { NewsEntry } from "pages/database/news";
import TranslationGridView from "./translation/grid";

import SeoHeader from "components/seoHeader";

/* CONSTANTS */
import {
  DatabaseFrontendPath,
  ResourceFrontendPath,
  ContentFrontendPath,
  GamesFrontendPath,
  AuthorFrontendPath,
  TranslationFrontendPath,
  NewsFrontendPath,
} from "constants/routing/frontend";

export default function DatabaseListRoutes() {
  const tabbedLinks = [
    {
      text: "Hacks",
      path: DatabaseFrontendPath + ContentFrontendPath,
      pathPart: ContentFrontendPath,
      element: <HackGridView />,
    },
    {
      text: "Translations",
      path: DatabaseFrontendPath + TranslationFrontendPath,
      pathPart: TranslationFrontendPath,
      element: <TranslationGridView />,
    },
    {
      text: "Resources",
      path: DatabaseFrontendPath + ResourceFrontendPath,
      pathPart: ResourceFrontendPath,
      element: <ResourceGridView />,
    },
    {
      text: "Games",
      path: DatabaseFrontendPath + GamesFrontendPath,
      pathPart: GamesFrontendPath,
      element: <GameGridView />,
    },
  ];

  const hiddenLinks = [
    {
      pathPart: ContentFrontendPath + "/entry/:id",
      element: <ContentEntry />,
    },
    {
      pathPart: AuthorFrontendPath + "/entry/:id",
      element: <AuthorEntry />,
    },
    {
      pathPart: GamesFrontendPath + "/entry/:id",
      element: <GameEntry />,
    },
    {
      pathPart: NewsFrontendPath + "/entry/:id",
      element: <NewsEntry />,
    },
  ];

  return (
    <>
      <SeoHeader 
        pageTitle={"Search"} 
      />

      <ButtonLinksTabbed id="blocklistTab" links={tabbedLinks} />
      
      <Routes>
        {tabbedLinks.map(({ pathPart, element }, i) => {
          return (
            <Route
              key={`blocklistRoute1-${i}`}
              path={pathPart}
              exact
              element={element}
            />
          );
        })}

        {hiddenLinks.map(({ pathPart, element }, i) => {
          return (
            <Route
              key={`searchRoute-${i}`}
              path={pathPart}
              exact
              element={element}
            />
          );
        })}
      </Routes>
    </>
  );
}
