/* LIBS */
import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

/* CUSTOMS */
import Paginator from "components/paginator";
import { websocketKeyAlert, pageSize } from "constants/models";
import { fetchPost } from "lib/fetch";
import Title from "components/title";
import Loader from "components/loader";
import { Table } from "components/table";
import CheckBox from "components/checkBox";
import { IsSortableField } from "lib/sortableField";
import { GetAge } from "lib/time";
import Timer, { PrintTime } from "components/timer";
import Button from "components/button";
import Pill from "components/pill";
import SeoHeader from "components/seoHeader";

/* SERVICES */
import { WebSocketContext } from "context/webSocket";
import { UserContext } from "context/user";

/* CONSTANTS */
import { SearchBackendPostfix, MetadataBackendPostfix, QueueBackendPath, ActionBackendPostfix } from "constants/routing/backend";
import ButtonGroupTabbed from "components/buttonGroupTabbed";

/* ICONS */
import { FaPlus as ClaimIcon } from "react-icons/fa";
import { PermissionQueue } from "constants/permissions";

import { IoIosArrowDown as SortDownIcon, IoIosArrowUp as SortUpIcon } from "react-icons/io";
import { MdSort as SortIcon } from "react-icons/md";
import { EntryPostfix, SubmissionQueueFrontendPath } from "constants/routing/frontend";
import { queueActionColorTable } from "constants/models/index";

export default function QueueGridView() {
  const [payload, setPayload] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const [sortField, setSortField] = useState("createdDateTime");
  const [sortDirection, setSortDirection] = useState("asc");
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [tab, setTab] = useState("open");
  const [checked, setChecked] = useState([]);

  const { notifications, removeNotification } = useContext(WebSocketContext);
  const { userContext } = useContext(UserContext);
  const { username, permissions } = userContext;

  useEffect(() => {
    fetchPost(QueueBackendPath + MetadataBackendPostfix, null)
      .then((resp) => {
        setMetadata(resp);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    setPage(0);
  }, [sortField, sortDirection, tab]);

  useEffect(() => {
    hackRequest();
  }, [page, sortField, sortDirection, tab]);

  const hackRequest = () => {
    setIsLoading(true);

    const body = {
      page: page,
      sortField: sortField,
      sortDirection: sortDirection,
      tab: tab,
    };

    fetchPost(QueueBackendPath + SearchBackendPostfix, body)
      .then((resp) => {
        setPayload(resp);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const paginationElement = (autoScroll, allowInputChange) => {
    if (!payload) {
      return;
    }

    return (
      <Paginator
        page={page}
        setPage={setPage}
        currentPageSize={payload.entries && payload.entries.length}
        pageWindowSize={pageSize}
        totalEntries={payload && payload.total}
        enableAutoScroll={autoScroll}
        allowInputChange={allowInputChange}
      />
    );
  };

  const actions = ({ id, action, analysis, incidentId }) => {
    const payload = {
      id: id,
      action: action,
      analysis: analysis,
      incidentId: incidentId,
    };

    fetchPost(QueueBackendPath + ActionBackendPostfix, payload)
      .then(() => {})
      .catch((resp) => {
        toast.error(resp.message);
      });
  };

  const checkboxCheck = (entry) => {
    let temp = [...checked];

    if (!temp.includes(entry)) {
      temp.push(entry);
    } else {
      const index = temp.indexOf(entry);
      temp.splice(index, 1);
    }

    setChecked(temp);
  };

  const checkboxCheckAll = (e) => {
    let checkedTemp = [];
    if (e.target.checked) {
      payload &&
        payload.entries.forEach((a) => {
          checkedTemp.push(a.id);
        });
    }
    setChecked(checkedTemp);
  };

  const headerColClass = "px-6 py-3 text-left tracking-wider";
  const rowColClass = "px-6 py-3 text-left truncate select-none";

  useEffect(() => {
    if (Object.keys(notifications[websocketKeyAlert]).length === 0) {
      return;
    }

    const alert = notifications[websocketKeyAlert][0];
    let temp = { ...payload };

    if (!temp.entries){
      temp.entries = [];
    }

    let tempMetadata = { ...metadata };

    const removeData = () => {
      temp.entries.forEach((tx) => {
        if (alert.entries.id === tx.id) {
          const index = temp.entries.indexOf(tx);
          temp.entries.splice(index, 1);
          tempMetadata.total--;
          return;
        }
      });
    };

    const addData = () => {
      temp.entries.push(alert.entries);
      tempMetadata.total++;
    };

    switch (alert.action) {
    case "add":
      if (tab === "open") {
        addData();
      }
      break;
    case "release":
      if (tab === "open") {
        addData();
      } else {
        removeData();
      }
      break;
    case "claim":
      if (tab !== "mine") {
        removeData();
      } else if (alert.entries.owner === username) {
        addData();
      }
      break;
    case "fp":
    case "tp":
      if (tab === "finished") {
        addData();
      } else {
        removeData();
      }
      break;
    default:
      throw new Error(`Invalid action: ${alert.action}`);
    }

    // Sort elements after inserting a new record
    temp.entries = temp.entries.sort((a, b) => {
      a = a[sortField];
      b = b[sortField];

      if (sortDirection === "asc") {
        return (a > b) - (a < b);
      } else {
        return (a < b) - (a > b);
      }
    });

    if (temp.entries.length > pageSize) {
      temp.entries = temp.entries.slice(0, pageSize);
    }

    let checkTemp = [];
    temp.entries.forEach((tx) => {
      if (checked.includes(tx.id)) {
        checkTemp.push(tx.id);
      }
    });

    setChecked(checkTemp);
    setPayload(temp);
    setMetadata(tempMetadata);
    removeNotification(websocketKeyAlert);
  }, [notifications]);

  const checkBoxAll = (
    <CheckBox
      checked={checked.length !== 0 && checked.length === payload.entries.length}
      onChange={(e) => checkboxCheckAll(e)}
      className="mt-3"
    />
  );

  const columns = {
    open: [
      {
        label: checkBoxAll,
        field: "checkbox",
        width: "w-1",
      },
      {
        label: "Action",
        field: "action",
        width: "w-10 max-lg:hidden",
      },
      {
        label: "Title",
        field: "title",
        width: "w-20",
      },
      {
        label: "Submitted By",
        field: "submitterUsername",
        width: "w-10 max-lg:hidden",
      },
      {
        label: "Release Date",
        field: "createdDateTime",
        width: "w-10 max-md:hidden",
      },
    ],
    mine: [
      {
        label: checkBoxAll,
        field: "checkbox",
        width: "w-1",
      },
      {
        label: "Action",
        field: "action",
        width: "w-10 max-lg:hidden",
      },
      {
        label: "Title",
        field: "title",
        width: "w-20",
      },
      {
        label: "Release Date",
        field: "createdDateTime",
        width: "w-10 max-md:hidden",
      },
    ],
    others: [
      {
        label: checkBoxAll,
        field: "checkbox",
        width: "w-1",
      },
      {
        label: "Action",
        field: "action",
        width: "w-10 max-lg:hidden",
      },
      {
        label: "Title",
        field: "title",
        width: "w-20",
      },
      {
        label: "Submitted By",
        field: "submitterUsername",
        width: "w-10 max-lg:hidden",
      },
      {
        label: "Release Date",
        field: "createdDateTime",
        width: "w-10 max-md:hidden",
      },
    ],
    finished: [
      {
        label: "Action",
        field: "action",
        width: "w-10 max-lg:hidden",
      },
      {
        label: "Title",
        field: "title",
        width: "w-20",
      },
      {
        label: "Submitted By",
        field: "submitterUsername",
        width: "w-10 max-lg:hidden",
      },
      {
        label: "Release Date",
        field: "createdDateTime",
        width: "w-10 max-md:hidden",
      },
    ],
  };

  if (!payload || !metadata) {
    return;
  }

  if (!permissions.includes(PermissionQueue)) {
    toast.error("You do not have permission to view this page.");
    return;
  }

  return (
    <>
      <SeoHeader pageTitle={`Queue - ${tab} - (${payload.entries ? payload.entries.length : 0})`} />
      <ButtonGroupTabbed
        id="queueTabs"
        properties={[
          {
            text: "Open",
            onClick: () => {
              setTab("open");
            },
            disabled: tab === "open",
          },
          {
            text: "Mine",
            onClick: () => {
              setTab("mine");
            },
            disabled: tab === "mine",
          },
          {
            text: "Others",
            onClick: () => {
              setTab("others");
            },
            disabled: tab === "others",
          },
          {
            text: "Finished",
            onClick: () => {
              setTab("finished");
              setSortField("createdDateTime");
              setSortDirection("desc");
            },
            disabled: tab === "finished",
          },
        ]}
      />
      <Title>Submission Queue</Title>
      <Loader isLoading={isLoading} />

      <div className="lg:grid lg:grid-cols-12 gap-3">
        <div className="col-span-12">
          <div className="flex items-center justify-end">{paginationElement(false, true)}</div>

          {payload.entries && payload.entries.length !== 0 ? (
            <Table>
              <thead>
                <tr>
                  {columns[tab].map((c, i) => {
                    let onClick = () => {};
                    let content = c.label;
                    let field = c.field;

                    let isSortable = false;

                    if (metadata.columns && Object.prototype.hasOwnProperty.call(metadata.columns, c.field)) {
                      isSortable = IsSortableField(metadata.columns[c.field].type);
                    }

                    if (isSortable) {
                      const className = "ml-2";

                      let icon = null;
                      if (sortField === field) {
                        if (sortDirection === "asc") {
                          icon = <SortDownIcon className={className} />;
                          onClick = () => {
                            setSortDirection("desc");
                            setSortField(field);
                          };
                        } else {
                          icon = <SortUpIcon className={className} />;
                          onClick = () => {
                            setSortDirection("asc");
                            setSortField(field);
                          };
                        }
                      } else {
                        icon = <SortIcon className={className} />;
                        onClick = () => {
                          setSortDirection("asc");
                          setSortField(field);
                        };
                      }

                      content = (
                        <div className="flex">
                          {content}
                          {icon}
                        </div>
                      );
                    }

                    return (
                      <th onClick={onClick} key={`header-${i}`} className={`${headerColClass} ${c.width}`}>
                        {content}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {payload.entries.map((c, i) => {
                  return (
                    <tr key={`tbody-${i}`}>
                      {columns[tab].map((x, i) => {
                        let className = "";
                        let content = null;
                        const fieldValue = c[x.field];
                        const width = x.width;

                        switch (x.field) {
                        case "checkbox":
                          content = <CheckBox checked={checked.includes(c.id)} onChange={() => checkboxCheck(c.id)} />;
                          break;
                        case "action":
                          content = <Pill text={fieldValue} color={queueActionColorTable[fieldValue]} />;
                          break;
                        case "title":
                          content = (
                            <Link className="text-sm underline" to={SubmissionQueueFrontendPath + EntryPostfix + c.id}>
                              {fieldValue}
                            </Link>
                          );
                          break;
                        case "submitterUsername":
                          content = fieldValue;
                          break;
                        case "createdDateTime":
                          content = <Timer startTime={GetAge(fieldValue, null)} />;
                          break;
                        case "completedDateTime":
                          content = <PrintTime seconds={GetAge(c.receivedTime, fieldValue)} />;
                          break;
                        case "claim":
                          content = (
                            <Button
                              onClick={() => {
                                actions({ id: [c.id], action: "claim" });
                              }}
                              className={"rounded-md"}
                            >
                              <ClaimIcon />
                            </Button>
                          );
                          break;
                        case "release":
                          content = null;
                          break;
                        default:
                          console.error("");
                        }

                        return (
                          <td key={`td-${i}`} className={`${rowColClass} ${width} ${className || ""}`}>
                            {content}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : null}
          <div className="flex items-center justify-end">{paginationElement(true, false)}</div>
        </div>
      </div>
    </>
  );
}
