import React from "react";
import { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

export const UserContext = createContext();

export function UserContextProvider({ children }) {
  useEffect(() => {
    const persistentUserData = localStorage.getItem("user-data");
    const storedValue = JSON.parse(persistentUserData);

    if (storedValue) {
      setUserContext_(storedValue);
    }
  }, []);

  const [userContext, setUserContext_] = useState({
    username: "",
    permissions: [],
    userId: "",
  });

  const setUserContext = ({ username, permissions, userId }) => {
    localStorage.setItem(
      "user-data",
      JSON.stringify({ username, permissions, userId })
    );
    setUserContext_({ username, permissions, userId });
  };

  const userContent = {
    userContext,
    setUserContext,
  };

  return (
    <UserContext.Provider value={userContent}>{children}</UserContext.Provider>
  );
}

UserContextProvider.propTypes = {
  children: PropTypes.any,
};
