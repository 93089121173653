import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";

const TextBox = ({ placeholder, value, id, className, onChange, onKeyDown: onKeyDown2, ...otherProps }) => {
  const [index, setIndex] = useState(null);

  const handleKeyDown = (e) => {
    if (e === null){
      return;
    }

    if (e.key === "Enter") {
      onChange(e.target.value);

      // Set e to a hook to let the onChange take effect.
      setIndex(e);
      return;
    }

    if (onKeyDown2) {
      onKeyDown2(index);
    }
  };

  useEffect(() => {
    if (!index){
      return;
    }

    setIndex(null);

    if (onKeyDown2) {
      onKeyDown2(index);
    }
  }, [index]);

  return (
    // Source: https://flowbite.com/docs/forms/floating-label/
    <div className={`relative ${className || ""}`}>
      <input
        id={id}
        className={`peer
      p-4 rounded-md bg-textBoxColor 
      opacity-75 outline outline-0
      border-solid 
      border-2 border-borderColor
      focus:bg-textBoxFocusColor text-contrastChangeText ${className || ""}`}
        placeholder={" "}
        defaultValue={value}
        // value={value} omitted for performance
        onBlur={(e) => {
          return onChange(e.target.value);
        }}
        onKeyDown={handleKeyDown}
        {...otherProps}
      />
      <label
        htmlFor={id}
        className="select-none absolute text-sm text-textBoxPlaceholderText
      duration-300 transform -translate-y-4 scale-75 top-4 z-2 origin-[0] 
      start-2.5 peer-focus:text-blue-600 
      peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
      peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4
      rtl:peer-focus:left-auto"
      >
        {placeholder}
      </label>
    </div>
  );
};

TextBox.displayName = "TextBox";

TextBox.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  id: PropTypes.string.isRequired,
  onKeyDown: PropTypes.func,
};

export default TextBox;
