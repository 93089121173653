import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { MdCancel } from "react-icons/md";
import { IoDocumentTextOutline } from "react-icons/io5";
import { largeArrayToBase64 } from "lib/strings";

export default function DropzoneComponent({
  className,
  onDropAction,
  allowMultiple,
  fileLimit,
  allowedFileTypes,
  isDisabled,
  files,
  setFiles,
}) {
  const [progresses, setProgresses] = useState({});

  if (!files) {
    files = [];
  }

  const { 
    acceptedFiles, 
    fileRejections, 
    getRootProps, 
    getInputProps,
  } = useDropzone({
    maxFiles: fileLimit,
    validator: validatorFunc, // https://react-dropzone.js.org/#section-accepting-specific-number-of-files
  });

  var userText = "Drag 'n' drop a file here, or click to select a file";

  if (allowMultiple) {
    userText = "Drag 'n' drop some files here, or click to select files";
  }

  function validatorFunc(file) {
    const ext = getExt(file.name);

    if (allowedFileTypes.length !== 0 && !allowedFileTypes.includes(ext)) {
      return {
        code: "invalid-extension",
        message: `${file.name} is not within the allowed filetype list:\n${allowedFileTypes.join(", ")}`,
      };
    }

    if (fileLimit < acceptedFiles.length + files.length + 1) {
      return {
        code: "too-many-files",
        message: `Too many files. The limit is ${fileLimit}.`,
      };
    }

    return null;
  }

  useEffect(() => {
    (async function () {
      let fileInformationList = [];
      if (allowMultiple) {
        fileInformationList = [...files];
      }
  
      const displayedErrors = {};
      fileRejections.forEach(({ file, errors }) => {
        errors.forEach((e) => {
          if (!displayedErrors[e.message]) {
            displayedErrors[e.message] = true;
            toast.error(e.message);
          }
        });
      });
  
      // Create an array of promises for reading files
      const fileReadPromises = acceptedFiles.map((fileInfo) => {
        return new Promise((resolve) => {
          const reader = new FileReader();
  
          reader.onprogress = (event) => {
            if (event.lengthComputable) {
              const percentCompleted = Math.round((event.loaded * 100) / event.total);

              setProgresses((prevProgresses) => ({
                ...prevProgresses,
                [fileInfo.path]: percentCompleted,
              }));
            }
          };
  
          reader.onload = () => {
            const imgData = new Uint8Array(reader.result);
            fileInformationList.push(new FileInformation("", imgData, fileInfo.name, true));
            setProgresses((prevProgresses) => {
              const newProgresses = { ...prevProgresses };
              delete newProgresses[fileInfo.path];
              return newProgresses;
            });
            resolve(); // Resolve the promise when done
          };
  
          reader.onerror = () => {
            toast.error("File loading failed");
            resolve(); // Resolve even on error to avoid hanging
          };
  
          // Read the file as an ArrayBuffer
          reader.readAsArrayBuffer(fileInfo);
        });
      });
  
      // Wait for all file read operations to complete
      await Promise.all(fileReadPromises);
  
      // Execute these after all files are processed
      if (setFiles) {
        setFiles(fileInformationList);
      }
  
      if (onDropAction) {
        onDropAction(acceptedFiles);
      }
    })();
  }, [acceptedFiles]);
  
  const removeItem = (index) => {
    const temp = [...files];
    temp.splice(index, 1);
    setFiles(temp);
  };

  const style = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 3,
    borderRadius: 2,
    borderColor: "var(--contrastChangeText)",
    borderStyle: "dashed",
    outline: "none",
    opacity: isDisabled ? 0.05 : 0.6,
    transition: "border .24s ease-in-out",
  };

  const inputProps = getInputProps();
  const rootProps = getRootProps({ style });

  const height = "max-h-14 min-h-14";
  const gridCols = `grid grid-cols-12 gap-1 mb-3 mt-3 ${height}`;

  return (
    <section className={className}>
      <div {...rootProps}>
        <input
          {...inputProps}
          multiple={allowMultiple}
          type={"file"}
          accept={allowedFileTypes && allowedFileTypes.join(",")}
          disabled={isDisabled}
        />
        <div className="select-none opacity-80">{userText}</div>
      </div>
      <aside>
        <h4>Files</h4>

        {Object.keys(files).length > 0 && files.map((file, i) => {
          const extension = getExt(file.fileName);
          const isImage = extension === ".png";

          return (
            <div key={i} className={gridCols}>
              <div className="col-span-2 my-auto mx-auto">
                {isImage ? <img className={height} src={file.blobUrl} alt={""} /> : <IoDocumentTextOutline className="size-12" />}
              </div>
              <div className="col-span-9 my-auto">
                {
                  <div title={file.fileName} className="text-left opacity-50 truncate">
                    {
                      file.blobUrl ?
                        <a href={file.blobUrl} target="_blank" rel="noreferrer" download={file.fileName}>{file.fileName}</a> 
                        : <>{file.fileName}</>}

                  </div>
                }
              </div>
              <div key={i} className="col-span-1 my-auto mx-auto">
                <MdCancel className="fill-red-500 hover:opacity-70 cursor-pointer" onClick={() => removeItem(i)} />
              </div>
            </div>
          );
        })}

        {
          Object.keys(progresses).map(filename => {
            var progress = "Loading..."; //`(${progresses[filename]}%)`;

            return(
              <div key={`progress-${filename}`} className={gridCols}>
                <div className="col-span-2 my-auto mx-auto"/>
                <div className="col-span-8 my-auto">
                  <div title={filename} className="text-left opacity-50 truncate">
                    {filename}
                  </div>
                </div>
                <div title={filename} className="col-span-1 my-auto text-left opacity-50">
                  {progress}
                </div>
              </div>
            );
          })
        }
      </aside>
    </section>
  );
}

function getExt(filename) {
  if (!filename || filename.length === ""){
    return;
  }

  var ext = filename.split(".").pop();

  if (ext === filename) {
    return "";
  }

  return "." + ext;
}

export class FileInformation {
  constructor(key, byteData, fileName, isChanged) {
    if (typeof isChanged !== "boolean"){
      throw new Error("isChanged must be a bool");
    }

    this.byteData = [];
    this.blobUrl = "";

    if (byteData && byteData.byteLength > 0){

      const blob = new Blob([byteData]);
      const url = URL.createObjectURL(blob);
      this.byteData = Array.from(byteData);
      this.blobUrl = url;
    }
    
    this.fileName = fileName;
    this.isChanged = isChanged;
    this.key = key;
  }

  /**
   * Converts FileInformation to a format for web requests
   */
  ConvertToRequest() {
    return { 
      key: this.key, 
      extension: getExt(this.fileName), 
      bytes: largeArrayToBase64(this.byteData), 
      isChanged: this.isChanged,
    };
  }
}

DropzoneComponent.propTypes = {
  onDropAction: PropTypes.func,
  className: PropTypes.string,
  allowMultiple: PropTypes.bool,
  fileLimit: PropTypes.number,
  isDisabled: PropTypes.bool,
  allowedFileTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  files: PropTypes.arrayOf(PropTypes.instanceOf(FileInformation)),
  setFiles: PropTypes.func,
};
