/* LIBS */
import React from "react";
import Pill from "components/pill";
import Button from "components/button";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Card from "components/card/index";

/* ICONS */
import { FaArrowRightToBracket as GotoIcon } from "react-icons/fa6";
import { removeMarkdownFormatting } from "lib/strings";
import Time from "components/time/index";
import Markdown from "react-markdown";

export const FriendlyTable = ({ data, thumbnailKey, preambleKey, columns, titleKey, gotoIdPath, preambleNoTruncate }) => {
  const ImageRenderer = (images, linkToAsset) => {

    // If not an array, assume it is a single image URL
    return (
      <Link key={thumbnailKey} className="text-sm underline" to={linkToAsset}>
        {images ? <img key={images} /* Force re-render */ src={images} 
          className="lg:w-full max-lg:w-1/2 object-cover" alt="" /> : null}
      </Link>
    );
  };

  return (
    <div>
      {data &&
        data.map((d, dataI) => {
          const headingLabel = "p-2 text-left";
          const thumbnailUrl = d[thumbnailKey];
          const isArrayOfImages = Array.isArray(thumbnailUrl);
          const isThumbnailOnSide = !isArrayOfImages ;
          const linkToAsset = gotoIdPath + d.id;

          const preambleText = d[preambleKey];
          return (
            <Card key={`friendlyTable-${dataI}`} className=" m-3 " title={d[titleKey]} to={linkToAsset}>
              <div className="grid grid-cols-12 gap-1 w-full">
                {isThumbnailOnSide ? (
                  <div 
                    className="max-lg:col-span-12 lg:col-span-2 flex items-center justify-center">
                    {ImageRenderer(thumbnailUrl, linkToAsset)}</div>
                ) : null}

                <div className={`${isThumbnailOnSide ? "lg:col-span-10" : "lg:col-span-12"} col-span-12 p-2`}>
                  {d[preambleKey] ? (
                    <div className={`text-contrastChangeText text-left ${preambleNoTruncate || "truncate-multiline"}`}>
                      {!preambleNoTruncate ? removeMarkdownFormatting(preambleText) : <Markdown>{preambleText}</Markdown>}
                    </div>
                  ) : null}
                  <div className="grid grid-cols-12 gap-1 mb-3 mt-3 w-full">
                    {columns.map((c, i) => {
                      var ccc;
                      c.width = c.width || "col-span-12 md:col-span-6 lg:col-span-3 sm:col-span-12";

                      var className = `${c.width} ${headingLabel}`;
                      const key = `friendlyTable-c-${i}-${dataI}`;

                      if (!d[c.field]) {
                        return <React.Fragment key={key}></React.Fragment>;
                      }

                      switch (c.dataType) {
                      case "link":
                        {
                          const idField = d[c.idField];
                          const field = d[c.field];

                          ccc = (
                            <Link className="text-sm underline" to={c.to + idField}>
                              {field}
                            </Link>
                          );
                        }
                        break;

                      case "write":
                        var field = d[c.field];

                        if (typeof field === "number") {
                          field = field.toLocaleString();
                        }

                        if (field instanceof Array){
                          field = field.join(", ");
                        }

                        ccc = <div>{field}</div>;
                        break;
                      case "time":
                        ccc = <Time time={d[c.field]}></Time>;
                        break;
                      case "pills": {
                        className = `${c.width} text-left p-2`;

                        ccc = (
                          <div className="flex flex-wrap p-4">
                            {d[c.field].map((element, i) => {
                              return <Pill key={`friendlyTable-pills-${i}-${dataI}`} color={"blue"} className={"m-1"} text={element} />;
                            })}
                          </div>
                        );
                        break;
                      }
                      default:
                        break;
                      }

                      return (
                        <div key={key} className={className}>
                          <h3 className={"text-contrastChangeText"}>{c.label}</h3>
                          {ccc}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className="flex justify-end">
                <Link className="text-sm underline" to={linkToAsset}>
                  <Button className="rounded-lg">
                    <div className="flex items-center justify-center">
                      <GotoIcon className="mr-2" /> Goto
                    </div>
                  </Button>
                </Link>
              </div>
            </Card>
          );
        })}
    </div>
  );
};

FriendlyTable.propTypes = {
  data: PropTypes.any,

  titleKey: PropTypes.string,
  thumbnailKey: PropTypes.string,
  preambleKey: PropTypes.string,
  gotoIdPath: PropTypes.string,
  columns: PropTypes.array,

  preambleNoTruncate: PropTypes.bool,
};
