/** LIBS */
import React, { useState, useContext } from "react";
import { toast } from "react-toastify";

/* CUSTOMS */
import SeoHeader from "components/seoHeader";
import { fetchPost } from "lib/fetch";
import Loader from "components/loader";

/* CONSTANTS */
import Title from "components/title/index";
import TextBox from "components/textBox/index";
import Button from "components/button/index";
import { LoginBackendPath } from "constants/routing/backend";
import { UserContext } from "context/user";
import Card from "components/card/index";

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { setUserContext } = useContext(UserContext);

  const hackRequest = () => {
    setIsLoading(true);

    const body = {
      username: username,
      password: password,
    };

    fetchPost(LoginBackendPath, body)
      .then((resp) => {
        const { username, permissions, userId } = resp;
        const userData = { username, permissions, userId };
        setUserContext(userData);

        toast.success("Logged in");
      })
      .catch((resp) => {
        toast.error(resp.message);
        setUserContext({ permissions: [], username: null, userId: null });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <SeoHeader pageTitle={"Search"} />
      <Loader isLoading={isLoading} />

      <Title>Login</Title>

      <Card title="Form">
        <div className="col-span-12 flex justify-center">
          <TextBox id={"username-textbox"} className={"mb-3 w-full max-w-xs"} placeholder={"Username"} onChange={setUsername} />
        </div>
        <div className="col-span-12 flex justify-center">
          <TextBox
            id={"username-textbox"}
            className={"mb-3 w-full max-w-xs"}
            placeholder={"Password"}
            type="password"
            onChange={setPassword}
          />
        </div>
        <div className="col-span-12 flex justify-center">
          <Button className={"rounded-lg max-w-xs"} onClick={hackRequest}>
            Submit
          </Button>
        </div>
      </Card>
    </>
  );
}
