import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "../button";

/* ICONS */
import {
  FaChevronLeft as LeftIcon,
  FaChevronRight as RightIcon,
} from "react-icons/fa";

const Pagination = ({
  page,
  setPage,
  currentPageSize,
  pageWindowSize,
  totalEntries,
  disabled = false,
  enableAutoScroll,
  allowInputChange,
}) => {
  const totalPages = Math.ceil(totalEntries / pageWindowSize);
  const [inputPage, setInputPage] = useState(page + 1); // 1-based for input

  const currentPageStart = page * pageWindowSize + 1;
  let currentPageEnd = currentPageStart + currentPageSize - 1;

  if (currentPageStart + currentPageSize > totalEntries) {
    currentPageEnd = totalEntries;
  }

  let pageText = "";
  if (currentPageStart && currentPageEnd && totalEntries) {
    pageText = ` of ${totalPages}`;
  } else {
    pageText = " of 1";
  }

  useEffect(() => {
    setInputPage(page+1);
  }, [page]);

  // Function to handle direct page input
  const handleInputChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value) && value >= 1 && value <= totalPages) {
      setPage(value - 1); 
    }
  };

  const changePage = (direction) => {
    const val = (prevPage) => Math.max(0, Math.min(totalPages - 1, prevPage + direction));
    setPage(val);

    if (!enableAutoScroll){
      return;
    }

    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const className =
    "inline-flex shadow-md hover:shadow-lg focus:shadow-lg mr-3 ml-3 mb-3 mt-3 overflow-hidden rounded-b-md rounded-t-md";

  return (
    <div className="flex items-center">
      <Button
        className={className}
        onClick={() => changePage(-1)}
        disabled={page <= 0 || disabled}
      >
        <LeftIcon />
      </Button>
    Page 
      { totalPages > 1 && allowInputChange ?
        <input
          type="number"
          value={inputPage}
          min="1"
          max={totalPages}
          onChange={handleInputChange}
          className="w-16 text-white text-center border border-borderColor bg-textBoxColor opacity-75 rounded-md p-1 mx-2"
          disabled={disabled}
        /> : ` ${page +1}` }
      {pageText}
      <Button
        className={className}
        onClick={() => changePage(1)}
        disabled={page >= totalPages - 1 || disabled}
      >
        <RightIcon />
      </Button>
      
    </div>
  );
};

Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  currentPageSize: PropTypes.number,
  pageWindowSize: PropTypes.number.isRequired,
  totalEntries: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  enableAutoScroll: PropTypes.bool,
  allowInputChange: PropTypes.bool,
};

export default Pagination;