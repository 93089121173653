import {
  SnesPlatform,
  NesPlatform,
  FdsPlatform,
  N64Platform,
} from "./platforms";

import crc32 from "crc/crc32";
import jsSha1 from "js-sha1";
import jsMd5 from "js-md5";

// @ts-check

/**
 * @param {Uint8Array} rom ROM to be examined
 * @param {string} system
 **/
export function getDatInfo(rom, system) {
  var platform;

  switch (system) {
  case "FDS":
    platform = new FdsPlatform(rom);
    break;
  case "NES":
    platform = new NesPlatform(rom);
    break;
  case "SNES":
    platform = new SnesPlatform(rom);
    break;
  case "N64":
    platform = new N64Platform(rom);
    break;
  default:
    console.error(`Bad system: ${system}`);
    return;
  }

  const out = platform?.normalize();

  if (out.message) {
    return {
      romWithHeader: generateHashes(rom),
      rom: generateHashes(out.rom),
      normalizeMessage: out.message,
    };
  }

  return { rom: generateHashes(rom) };
}

/**
 * @param {Uint8Array} rom
 **/
function generateHashes(rom) {
  const crc32Hash = crc32(rom).toString(16);
  const sha1Hash = jsSha1.sha1(rom);
  const md5Hash = jsMd5.md5(rom);

  return { crc32Hash, sha1Hash, md5Hash };
}

/**
 * @param {resp} object
 * @param {file} object
 * @param {dats} object
 **/
export function generateHashMessage(resp, file, dats) {
  var lines = [];

  var retVal = {error: null, message: ""};

  if (resp.isValid) {
    lines.push(`* Database match: ${resp.fileName}`);
    lines.push(`  * Database: ${resp.setName}`);
  } else {
    retVal.error = `File is not within our database: ${file.name}`;
    lines.push(`* Filename: ${file.name}`);
  }

  if (dats.normalizeMessage) {
    lines.push(`  * Format: ${dats.normalizeMessage}`);
  }

  if (dats.romWithHeader) {
    lines.push(
      `  * File SHA-1: \`${dats.romWithHeader.sha1Hash}\``
    );
    lines.push(
      `  * File CRC32: \`${dats.romWithHeader.crc32Hash}\``
    );
  }

  if (dats.rom) {
    lines.push(`  * ROM SHA-1: \`${dats.rom.sha1Hash}\``);
    lines.push(`  * ROM CRC32: \`${dats.rom.crc32Hash}\``);
  }

  retVal.message = lines.join("\n");

  return retVal;
}
