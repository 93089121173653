/* LIBS*/
import React, { useState } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import Markdown from "react-markdown";

/* CUSTOM */
import Time from "components/time";
import Button from "components/button";
import TextArea from "components/textArea";
import Avatar from "components/avatar";
import DropdownDot from "components/dropdownDot";

/** STYLING */
import "./commentSection.css";

const CommentSection = ({ comments, setComments, userId, username, onAdd, onReply, onDelete, onEdit }) => {
  const [newComment, setNewComment] = useState("");
  const [replyTo, setReplyTo] = useState(null);
  const [newReply, setNewReply] = useState("");

  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editingText, setEditingText] = useState("");

  const commentEmptyError = "Comment cannot be empty";

  if (!comments) {
    return null;
  }

  const addComment = async () => {
    if (!newComment.trim()) {
      toast.error(commentEmptyError);
      return;
    }

    try {
      const { id } = await onAdd(newComment);

      if (!id) {
        throw new Error("Id empty");
      }

      const newCommentObj = {
        id: id,
        userId: userId,
        username: username,
        text: newComment,
        isDeleted: false,
        createdDateTime: new Date().toISOString(),
        parentId: "",
      };

      setComments([...comments, newCommentObj]);
      setNewComment("");
    } catch (resp) {
      toast.error(resp.message);
    }
  };

  const addReply = async (parentId) => {
    if (!newReply.trim()) {
      toast.error(commentEmptyError);
      return;
    }

    if (!parentId) {
      throw new Error("Parent id invalid");
    }

    try {
      const { id } = await onAdd(newReply, parentId);

      if (!id) {
        throw new Error("Id empty");
      }

      const reply = {
        id: id,
        userId: userId,
        username: username,
        text: newReply,
        isDeleted: false,
        createdDateTime: new Date().toISOString(),
        parentId: parentId,
      };

      setComments([...comments, reply]);
      setNewReply("");
      setReplyTo(null);
    } catch (resp) {
      toast.error(resp.message);
    }
  };

  const softDeleteComment = async (commentId) => {
    try {
      await onDelete(commentId);
      const deleteComment = (comments) => comments.map((comment) => (comment.id === commentId ? { ...comment, 
        isDeleted: true,
        text: "",
        modifiedDateTime: new Date().toISOString(),
      } : comment));
      setComments(deleteComment(comments));
    } catch (resp) {
      toast.error(resp.message);
    }
  };

  const startEditing = (comment) => {
    setEditingCommentId(comment.id);
    setEditingText(comment.text);
  };

  const saveEdit = async () => {
    if (!editingText.trim()) {
      toast.error(commentEmptyError);
      return;
    }

    try {
      await onEdit(editingCommentId, editingText);

      const updatedComments = comments.map((comment) =>
        comment.id === editingCommentId ? { 
          ...comment, 
          text: editingText, 
          modifiedDateTime: new Date().toISOString(),
        } : comment
      );

      setComments(updatedComments);
      setEditingCommentId(null);
      setEditingText("");
    } catch (resp) {
      toast.error(resp.message);
    }
  };

  const cancelEdit = () => {
    setEditingCommentId(null);
    setEditingText("");
  };

  const renderComments = (comments, parentId = null, depth) => (
    <div>
      {comments
        .filter((comment) => {
          return comment.parentId === parentId;
        })
        .map((comment) => {
          const isModified = comment.modifiedDateTime !== 0 && comment.modifiedDateTime;
          const displayTime = isModified ? comment.modifiedDateTime: comment.createdDateTime;

          return (
            <div key={comment.id} className={`mt-5 mb-5 ${depth > 0 && depth < 2 ? "ml-20" : null}`}>
              <div className="flex justify-start space-x-4">
                <div className="w-20">
                  <Avatar className="w-20" size={3} username={comment.username} />
                </div>

                <div className={`${comment.isDeleted ? "opacity-50" : ""} w-full`}>
                  <div className="flex justify-start space-x-4">
                    <div className="font-bold">{comment.username}</div>
                    <Time className="text-xs mt-1" time={displayTime} fromNow />
                    {isModified && <div className="text-xs mt-1 text-gray-500">(Modified)</div>}
                  </div>

                  {comment.isDeleted ? (
                    <div className="italic text-gray-500">This comment has been deleted</div>
                  ) : (
                    <>
                      {editingCommentId === comment.id ? (
                        <div>
                          <TextArea
                            className="w-full"
                            value={editingText}
                            onChange={(value) => {
                              setEditingText(value);
                            }}
                            placeholder="Edit your comment..."
                          />
                          <div className="flex justify-start space-x-4 mt-4">
                            <Button className="mt-3 rounded-lg" onClick={saveEdit}>
                              Save
                            </Button>
                            <Button secondary={true} className="mt-3 rounded-lg" onClick={cancelEdit}>
                              Cancel
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <>
                          <>
                            <Markdown>{comment.text}</Markdown>
                            <div className="flex justify-start space-x-4 mt-4">
                              {onReply ? (
                                <Button className="rounded-lg" onClick={() => setReplyTo(comment.id)}>
                                  Reply
                                </Button>
                              ) : null}
                            </div>
                          </>
                        </>
                      )}
                    </>
                  )}
                </div>

                <div className="flex justify-end">
                  <div>
                    <DropdownDot
                      options={[
                        { label: "Edit", onClick: () => startEditing(comment) },
                        { label: "Delete", onClick: () => softDeleteComment(comment.id) },
                      ]}
                    />
                  </div>
                </div>
              </div>

              {replyTo === comment.id && (
                <div className="mt-3">
                  <TextArea
                    className={"w-full"}
                    value={newReply}
                    onChange={(value) => setNewReply(value)}
                    placeholder="Write a reply..."
                  />

                  <div className="flex justify-start space-x-4 mt-4">
                    <Button className="mt-3 rounded-lg" disabled={!newReply} onClick={() => addReply(comment.id)}>
                      Add
                    </Button>
                    <Button
                      secondary={true}
                      className="mt-3 rounded-lg"
                      onClick={() => {
                        setReplyTo(null);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              )}
              {renderComments(comments, comment.id, depth + 1)}
            </div>
          );
        })}
    </div>
  );

  return (
    <div className="comment-section">
      <div className="comment-input">
        {onAdd ? (
          <>
            <TextArea
              id={"comment-text-area"}
              className={"w-full"}
              value={newComment}
              onChange={(value) => setNewComment(value)}
              placeholder="Add a comment..."
            />
            <Button className="rounded-lg" onClick={addComment}>
              Add
            </Button>
          </>
        ) : (
          <div className="italic text-gray-500">You cannot add comments</div>
        )}
      </div>
      <div className="comments-list">{renderComments(comments, "", 0)}</div>
    </div>
  );
};

CommentSection.propTypes = {
  comments: PropTypes.array.isRequired,
  setComments: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  onEdit: PropTypes.func,
  onAdd: PropTypes.func,
  onReply: PropTypes.func,
  onDelete: PropTypes.func,
};

export default CommentSection;
