/* LIBS */
import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";

/* ASSETS */
import logo from "./assets/logo.png";
import logo2 from "./assets/logo2.png";

import twitter from "./assets/twitter_logo.png";
import discord from "./assets/discord_logo.png";
import { slide as Menu } from "react-burger-menu";

/* CUSTOMS */
import { UserContextProvider } from "./context/user";
import Button from "components/button";
import { News, AdvancedSearch, Search, Submission, Help } from "./pages";
import QueueMain from "./pages/queue";
import ImageHover from "components/imageHover";

/* CONSTANTS */
import {
  HomeFrontendPath,
  DatabaseFrontendPath,
  AdvancedSearchFrontendPath,
  SubmissionFrontendPath,
  ListPostfix,
  HelpFrontendPath,
  ForumFrontendPath,
  SubmissionQueueFrontendPath,
  LoginFrontendPath,
  ContentFrontendPath,
} from "./constants/routing/frontend";
import { waitTime } from "./constants/toasty";

/* ICONS */
import HomeIcon1 from "assets/icon-home-1.png";
import HomeIcon2 from "assets/icon-home-2.png";

import ForumIcon1 from "assets/icon-forum-1.png";
import ForumIcon2 from "assets/icon-forum-2.png";

import SearchIcon1 from "assets/icon-search-1.png";
import SearchIcon2 from "assets/icon-search-2.png";

import SearchAdvancedIcon1 from "assets/icon-search-advanced-1.png";
import SearchAdvancedIcon2 from "assets/icon-search-advanced-2.png";

import UploadIcon1 from "assets/icon-upload-1.png";
import UploadIcon2 from "assets/icon-upload-2.png";

import HelpIcon1 from "assets/icon-help-1.png";
import HelpIcon2 from "assets/icon-help-2.png";

import LoginIcon1 from "assets/icon-login-1.png";
import LoginIcon2 from "assets/icon-login-2.png";

import QueueIcon1 from "assets/icon-queue-1.png";
import QueueIcon2 from "assets/icon-queue-2.png";

import { CiMenuBurger } from "react-icons/ci";

/* STYLING */
import "./styling/app.css";
import "react-toastify/dist/ReactToastify.css";
import {Login} from "pages/user";
import ThemeToggle from "mode";
import { ThemeProvider } from "context/themeProvider";
import AprilFoolsPopupBox from "aprilFools";

function App() {
  const routes = [
    {
      path: HomeFrontendPath + "/*",
      element: <News />,
    },
    {
      path: DatabaseFrontendPath + "/*",
      element: <Search />,
    },
    {
      path: AdvancedSearchFrontendPath + "/*",
      element: <AdvancedSearch />,
    },
    {
      path: SubmissionFrontendPath + "/*",
      element: <Submission />,
    },
    {
      path: SubmissionQueueFrontendPath + "/*",
      element: <QueueMain />,
    },
    {
      path: HelpFrontendPath + "/*",
      element: <Help />,
    },
    {
      path: LoginFrontendPath + "/*",
      element: <Login />,
    },
  ];

  const socialMedia = [
    {
      image: twitter,
      url: "https://twitter.com/romhackdoting",
    },
    {
      image: discord,
      url: "https://discord.gg/uAufcgz",
    },
  ];

  const menuBarOptions = [
    {
      label: "Home",
      path: HomeFrontendPath,
      icon: <ImageHover src={HomeIcon1} hoverSrc={HomeIcon2} />,
    },
    {
      label: "Database",
      path: DatabaseFrontendPath + ContentFrontendPath,
      icon: <ImageHover src={SearchIcon1} hoverSrc={SearchIcon2} />,
    },
    {
      label: "Submissions",
      path: SubmissionFrontendPath + ListPostfix,
      icon: <ImageHover src={UploadIcon1} hoverSrc={UploadIcon2} />,
    },
    {
      label: "Advanced Search",
      path: AdvancedSearchFrontendPath + ContentFrontendPath,
      icon: <ImageHover src={SearchAdvancedIcon1} hoverSrc={SearchAdvancedIcon2} />,
    },
    {
      label: "Queue",
      path: SubmissionQueueFrontendPath + ListPostfix,
      icon: <ImageHover src={QueueIcon1} hoverSrc={QueueIcon2} />,
    },
    {
      label: "Forum",
      path: ForumFrontendPath,
      icon: <ImageHover src={ForumIcon1} hoverSrc={ForumIcon2} />,
      isExternalLink: true,
    },
    {
      label: "Help",
      path: HelpFrontendPath,
      icon: <ImageHover src={HelpIcon1} hoverSrc={HelpIcon2} />,
    },
    {
      label: "Login",
      path: LoginFrontendPath,
      icon: <ImageHover src={LoginIcon1} hoverSrc={LoginIcon2} />,
    },
  ];

  const isAprilFirst = () => {
    const today = new Date();
    return today.getMonth() === 3 && today.getDate() === 1;
  };



  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  // Function to handle the button click and toggle menu state
  const toggleMenu = () => {
    setIsOpen((prevState) => !prevState);
  };

  // Function to handle when menu state changes
  const handleStateChange = (state) => {
    setIsOpen(state.isOpen);
  };

  const [modalOpen, setModalOpen] = useState(isAprilFirst());

 

  const SideBar = () => {
    return (
      <aside className={"fixed top-0 left-0 w-16 h-screen transition-transform -translate-x-full sm:translate-x-0"} aria-label="Sidebar">
        <div className="h-full px-3 py-4 overflow-y-auto overflow-x-hidden bg-menuColor">
          {menuBarOptions.map((c, i) => {
            let { label, path, icon, isExternalLink } = c;

            const linkClassName = "text-base text-sm text-white hover:text-accent";
            const iconClassName = "block mb-1 sm:inline-block sm:mt-0 mr-1 w-16 pixelImage";

            const props = { ...icon.props, className: iconClassName };
            let icon1 = React.cloneElement(icon, props);

            // Make sure to have a div height so the icon tilt has some slack space.
            const child = (
              <div data-tooltip-id="my-tooltip" data-tooltip-content={label}>
                <div className={`mb-5 ${i === 0 && "mt-24 "} h-12`}>{icon1}</div>
              </div>
            );

            if (isExternalLink) {
              return (
                <a key={`menu-${i}`} href={path} className={linkClassName}>
                  {child}
                </a>
              );
            }

            return (
              <Link key={`menu-${i}`} to={path} className={linkClassName}>
                {child}
              </Link>
            );
          })}
          <Tooltip id="my-tooltip" place="left" className="z-50" />
        </div>
      </aside>
    );
  };

  return (
    <>
      <ThemeProvider>
        <UserContextProvider>
          <AprilFoolsPopupBox modalOpen={modalOpen} setModalOpen={setModalOpen} />

          {/* Burger Menu */}
          <Menu
            left
            width={300}
            isOpen={isOpen}
            onStateChange={handleStateChange}
            className="bg-menuColor relative h-full"
            menuClassName="bg-menuColor-800 p-4"
            itemListClassName="space-y-2"
          >
            {menuBarOptions.map((c, i) => {
              let { label, path, icon, isExternalLink } = c;

              const iconClassName = "pixelImage";

              const props = { ...icon.props, className: iconClassName };
              let icon1 = React.cloneElement(icon, props);

              const child = (
                <div className="flex items-center space-x-2 p-2 rounded space-x-4">
                  <span className="text-xl">{icon1}</span>
                  <span>{label}</span>
                </div>
              );

              if (isExternalLink) {
                return (
                  <a key={`menu-${i}`} href={path} onClick={toggleMenu} className="text-linkBlue hover:text-linkBlueHover">
                    {child}
                  </a>
                );
              }

              return (
                <Link key={`menu-${i}`} to={path} onClick={toggleMenu} className="text-linkBlue hover:text-linkBlueHover">
                  {child}
                </Link>
              );
            })}
          </Menu>

          {SideBar()}

          <div className={"sm:ml-16"}>
            {/* Navbar*/}
            {/* https://codepen.io/bradtraversy/pen/JgXqBL*/}
            <div className="flex flex-col min-h-full min-h-[100vh] ">
              <nav className="flex items-center justify-between flex-wrap menu-bar p-3 shadow-lg">
                <div className="flex space-x-4 ">
                  <Button onClick={toggleMenu} className="rounded ">
                    <CiMenuBurger />
                  </Button>
                  <span
                    className="cursor-pointer"
                    onClick={() => {
                      navigate(`${HomeFrontendPath}`);
                    }}
                  >
                    <img src={isAprilFirst() ? logo2 : logo} alt="" className="h-16 pixelImage" />
                  </span>
                </div>

                <div>
                  <div className="mt-3 w-full block flex-grow sm:flex sm:items-center sm:w-auto">
                    <div className="sm:flex-grow">
                      <div className="flex items-center justify-end gap-3 mr-3">
                        <ThemeToggle />

                        {socialMedia.map((c, i) => {
                          return (
                            <div key={i}>
                              <a target="_blank" href={c.url} rel="noreferrer">
                                <img src={c.image} alt="" className="pixelImage" />
                              </a>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </nav>

              <div className="pr-3 pl-3">
                <div>
                  <Routes>
                    {routes.map(({ path, element }, i) => {
                      return <Route key={`appRoute-${i}`} path={path} element={element} exact />;
                    })}
                  </Routes>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer position="bottom-right" autoClose={waitTime} />
        </UserContextProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
