/* LIBS */
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

/* CUSTOMS */
import Paginator from "components/paginator";
import { pageSize } from "constants/models";
import { fetchPost } from "lib/fetch";
import Title from "components/title";
import { SortableTable } from "components/sortableTable";
import Loader from "components/loader";
import AdvancedFilterCard from "components/advancedFilterCard/index";

/* CONSTANTS */
import { ContentBackendPath, SearchBackendPostfix, MetadataBackendPostfix } from "constants/routing/backend";
import { requestFormatFilter } from "lib/elastic";
import {
  AuthorFrontendPath,
  ContentFrontendPath,
  DatabaseFrontendPath,
  EntryPostfix,
  GamesFrontendPath,
} from "constants/routing/frontend";

export default function ContentGridView() {
  const [payload, setPayload] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const [sortField, setSortField] = useState("releaseDate");
  const [sortDirection, setSortDirection] = useState("desc");
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState([]);
  const [queryString, setQueryString] = useState("");

  const columns = [
    {
      label: "Original Game",
      idField: "gameId",
      field: "gameTitle",
      width: "w-20",
      dataType: "link",
      to: DatabaseFrontendPath + GamesFrontendPath + EntryPostfix,
    },
    {
      label: "Title",
      idField: "id",
      field: "title",
      width: "w-20",
      dataType: "link",
      to: DatabaseFrontendPath + ContentFrontendPath + EntryPostfix,
    },
    {
      label: "Released By",
      idField: "authorId",
      field: "authorName",
      width: "w-10 max-lg:hidden",
      dataType: "link",
      to: DatabaseFrontendPath + AuthorFrontendPath + EntryPostfix,
    },
    {
      label: "Platform",
      field: "platform",
      width: "w-10 max-lg:hidden",
      dataType: "write",
    },
    {
      label: "Release Date",
      field: "releaseDate",
      width: "w-10 max-md:hidden",
      dataType: "dateTime",
    },
    {
      label: "Tags",
      field: "tags",
      width: "w-20 max-md:hidden",
      dataType: "write",
    },
  ];

  useEffect(() => {
    fetchPost(ContentBackendPath + MetadataBackendPostfix, null)
      .then((resp) => {
        setMetadata(resp);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    setPage(0);
  }, [sortField, sortDirection, filters]);

  useEffect(() => {
    hackRequest();
  }, [page, sortField, sortDirection, filters]);

  const hackRequest = () => {
    setIsLoading(true);

    const body = {
      page: page,
      sortField: sortField,
      sortDirection: sortDirection,
      filters: requestFormatFilter(filters),
      queryString: queryString,
    };

    fetchPost(ContentBackendPath + SearchBackendPostfix, body)
      .then((resp) => {
        setPayload(resp);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const paginationElement = (autoScroll, allowInputChange) => {
    if (!payload) {
      return;
    }

    return (
      <Paginator
        page={page}
        setPage={setPage}
        currentPageSize={payload.entries && payload.entries.length}
        pageWindowSize={pageSize}
        totalEntries={payload && payload.total}
        enableAutoScroll={autoScroll}
        allowInputChange={allowInputChange}
      />
    );
  };

  return (
    <>
      <Title>Content</Title>
      <Loader isLoading={isLoading} />

      <div className="lg:grid lg:grid-cols-12 gap-3">
        <div className="col-span-4">
          <AdvancedFilterCard
            filters={filters}
            setFilters={setFilters}
            setQueryString={setQueryString}
            searchUri={ContentBackendPath}
            onClick={hackRequest}
            metadata={metadata}
          />
        </div>
        <div className="col-span-8">
          <div className="flex items-center justify-end">{paginationElement(false, true)}</div>
          <SortableTable
            data={payload && payload.entries}
            metadataColumn={metadata && metadata.columns}
            columns={columns}
            setSortField={setSortField}
            setSortDirection={setSortDirection}
            sortField={sortField}
            sortDirection={sortDirection}
          />
          <div className="flex items-center justify-end">{paginationElement(true, false)}</div>
        </div>
      </div>
    </>
  );
}
