/* LIBS */
import React from "react";
import PropTypes from "prop-types";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";

// https://codepen.io/mael2210/pen/EgQveY
export default function MultiSelect({
  value,
  options,
  placeholder,
  className,
  isMulti,
  disabled,

  onRemove,
  onCreate,
  onSelect,
  onClear,

  label,

  onInputChange,

  onMenuOpen,
  onMenuClose,

  ...otherProps
}) {
  if (!options) {
    options = [];
  }

  if (!value) {
    value = [];
  }

  const borderRadius = "99px";

  const keyToFuncMap = {
    "select-option": onSelect,
    "deselect-option": null,
    "remove-value": onRemove,
    "pop-value": null,
    "set-value": null,
    "clear": onClear,
    "create-option": onCreate,
  };

  const onChange = (opt, meta) => {
    const key = meta.action;

    if (!(key in keyToFuncMap)) {
      console.error(`onChange: invalid action: ${meta.action}`);
      return;
    }

    const f = keyToFuncMap[key];

    if (!f) {
      console.error(`onChange: invalid function for action: ${meta.action}`);
      return;
    }

    f(opt, meta);
  };

  // https://react-select.com/components
  const selectorStyles = {
    clearIndicator: (provided) => ({
      ...provided,
      color: "var(--borderColor)",
    }),
    control: (provided) => ({
      ...provided,
      zIndex: 0,
      backgroundColor: "var(--textBoxColor)",
      borderColor: "var(--borderColor)",
      boxShadow: "#fff",
      //padding: "4px",
      opacity: disabled ? "50%" : "75%",
      borderWidth: "2px",
      "&:hover": {
        backgroundColor: "var(--textBoxFocusColor)",
      },
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "var(--borderColor)",
    }),
    downChevron: (provided) => ({
      ...provided,
    }),
    crossIcon: (provided) => ({
      ...provided,
    }),
    group: (provided) => ({
      ...provided,
    }),
    groupHeading: (provided) => ({
      ...provided,
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: "55px",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: "var(--borderColor)",
    }),
    input: (provided) => ({
      ...provided,
      color: "var(--contrastChangeText)",
    }),
    loadingIndicator: (provided) => ({
      ...provided,
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "var(--dropdownColor)",
      zIndex: 3,
    }),
    menuList: (provided) => ({
      ...provided,
      color: "var(--textWithContrastDarkBg)",
    }),
    menuPortal: (provided) => ({
      ...provided,
    }),
    loadingMessage: (provided) => ({
      ...provided,
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "var(--primaryBlue)",
      color: "white",
      borderRadius: borderRadius,
    }),
    multiValueContainer: (provided) => ({
      ...provided,
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "white",
      marginRight: "10px",
      marginLeft: "10px",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      borderRadius: `0px ${borderRadius} ${borderRadius} 0px`,
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        color: "var(--contrastChangeText)",

        backgroundColor: isDisabled
          ? undefined
          : isSelected
            ? "var(--dropdownFocusColor)"
            : isFocused
              ? "var(--dropdownFocusColor)"
              : undefined,
      };
    },
    placeholder: (provided) => ({
      ...provided,
      textAlign: "left",
      color: "var(--textWithContrastSecondaryDarkBg)",
    }),
    selectContainer: (provided) => ({
      ...provided,
    }),
    singleValue: (provided) => ({
      ...provided,
      textAlign: "left",
      color: "var(--contrastChangeText)",
    }),
    valueContainer: (provided) => ({
      ...provided,
    }),
  };

  const allowCreation = onCreate !== undefined;

  const Tag = allowCreation ? CreatableSelect : Select;

  return (
    <div {...otherProps} className={`relative  ${disabled ? "opacity-50" : ""} ${className || ""}`}>
      <Tag
        {...otherProps}

        // https://stackoverflow.com/questions/50412843/how-to-programmatically-clear-reset-react-select
        // Setting the key to contain the value forces a re-render when cleared
        key={`multi-select-${value}`}
        className=""
        styles={selectorStyles}
        isMulti={isMulti}
        isClearable={onClear !== undefined}
        value={value || ""}
        options={options}
        onChange={(opt, meta) => onChange(opt, meta)}
        isDisabled={disabled}
        onInputChange={(value, meta) => onInputChange(value, meta)}
        placeholder={" "}

        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
      />
      <label
        className={`absolute text-sm text-textBoxPlaceholderText
        duration-300 transform -translate-y-4 scale-75 top-4 z-2 origin-[0] start-2.5 
        peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
        peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 
        rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto select-none`}
      >
        {placeholder}
      </label>
    </div>
  );
}

MultiSelect.propTypes = {
  value: PropTypes.any,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  isMulti: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,

  onRemove: PropTypes.func,
  onCreate: PropTypes.func,
  onSelect: PropTypes.func,
  onClear: PropTypes.func,

  onInputChange: PropTypes.func,

  label: PropTypes.string,

  onMenuOpen: PropTypes.func,
  onMenuClose: PropTypes.func,

};
