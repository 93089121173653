/* LIBS */
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

/* CUSTOMS */
import Paginator from "components/paginator";
import { pageSize } from "constants/models";
import { fetchPost } from "lib/fetch";
import Title from "components/title";
import Loader from "components/loader";
import { FriendlyTable } from "components/friendlyTable";
import { FriendlyFilterCard } from "components/friendlyFilterCard";

/* CONSTANTS */
import { GameBackendPath, FriendlySearchBackendPostfix, FriendlyMetadataBackendPostfix } from "constants/routing/backend";
import { requestFormatFilter } from "lib/elastic";
import { DatabaseFrontendPath, EntryPostfix, GamesFrontendPath } from "constants/routing/frontend";

export default function GameGridView() {
  const [payload, setPayload] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const [sortField, ] = useState("title");
  const [sortDirection, ] = useState("asc");
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState([]);

  const [queryString, setQueryString] = useState("");

  const columns = [
    {
      label: "Platform",
      field: "platform",
      width: "",
      dataType: "write",
    },
    {
      label: "Publisher",
      field: "publisher",
      width: "",
      dataType: "write",
    },
    {
      label: "Tags",
      field: "tags",
      width: "col-span-12",
      dataType: "pills",
    },
  ];

  useEffect(() => {
    setIsLoading(true);
    fetchPost(GameBackendPath + FriendlyMetadataBackendPostfix, null)
      .then((resp) => {
        setMetadata(resp);
        setFilters(Array(Object.keys(resp.filters).length));
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    setPage(0);
  }, [sortField, sortDirection, filters]);

  useEffect(() => {
    hackRequest();
  }, [page, sortField, sortDirection, filters]);

  const hackRequest = () => {
    setIsLoading(true);

    const body = {
      page: page,
      sortField: sortField,
      sortDirection: sortDirection,
      filters: requestFormatFilter(filters),
      queryString: queryString,
    };

    fetchPost(GameBackendPath + FriendlySearchBackendPostfix, body)
      .then((resp) => {
        setPayload(resp);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const paginationElement = (autoScroll, allowInputChange) => {
    if (!payload) {
      return;
    }

    return (
      <Paginator
        page={page}
        setPage={setPage}
        currentPageSize={payload.entries && payload.entries.length}
        pageWindowSize={pageSize}
        totalEntries={payload && payload.total}
        enableAutoScroll={autoScroll}
        allowInputChange={allowInputChange}
      />
    );
  };

  return (
    <>
      <Title>Games</Title>
      <Loader isLoading={isLoading} />

      <div className="lg:grid lg:grid-cols-12 gap-3">
        <div className="col-span-12">
          <FriendlyFilterCard
            filters={filters}
            setFilters={setFilters}
            setQueryString={setQueryString}
            searchUri={GameBackendPath}
            onClick={hackRequest}
            metadata={metadata}
          />
        </div>

        <div className="col-span-12">
          <div className="flex items-center justify-end">{paginationElement(false, true)}</div>
          <FriendlyTable
            data={payload && payload.entries}
            titleKey="title"
            thumbnailKey="thumbnailUrl"
            preambleKey="description"
            gotoIdPath={DatabaseFrontendPath + GamesFrontendPath + EntryPostfix}
            columns={columns}
          />
          <div className="flex items-center justify-end">{paginationElement(true, false)}</div>
        </div>
      </div>
    </>
  );
}
