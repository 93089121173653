/* LIBS */
import React from "react";
import PropTypes from "prop-types";
import Time from "components/time";
import { Link } from "react-router-dom";

/* CUSTOMS */
import { Table } from "components/table";
import Card from "components/card";
import { AuthorFrontendPath, DatabaseFrontendPath } from "constants/routing/frontend";
import {
  GamesFrontendPath,
} from "constants/routing/frontend";

export default function Bob({ payload, isGame }) {
  if (!payload){
    return;
  }

  const leftTitle = "flex ml-3 justify-start font-bold";
  const rightText = "text-left";
  const margin = "ml-1 mb-3";

  const row = (label, content) => {
    return (
      <tr key={label}>
        <td className={leftTitle}>{label}</td>
        <td className={rightText}>{content}</td>
      </tr>
    );
  };

  var columns = [];

  if (payload.gameId) {
    const url = `${DatabaseFrontendPath}${GamesFrontendPath}/entry/${payload.gameId}`;
    columns.push(row("Game", <Link to={url}>{payload.gameTitle}</Link>));
  }

  if (payload.authorName) {
    const linky = <Link to={DatabaseFrontendPath + AuthorFrontendPath + "/entry/" + payload.authorId}>
      {payload.authorName}</Link>;

    columns.push(row("Released By", linky));
  }

  if (payload.tags) {
    columns.push(row("Tags", payload.tags.join(", ")));
  }

  if (payload.os) {
    columns.push(row("OS", payload.os));
  }

  if (payload.experience) {
    columns.push(row("Experience", payload.experience));
  }

  if (payload.category) {
    columns.push(row("Category", payload.category));
  }

  if (payload.platform) {
    columns.push(row("Platform", payload.platform));
  }

  if (payload.license) {
    columns.push(row("License", payload.license));
  }

  if (payload.sourceCodeUrl){
    const linky = <a target="_blank" href={payload.sourceCodeUrl} rel="noreferrer">Yes</a>;
    columns.push(row("Source Code", linky));
  }

  if (payload.patching) {
    columns.push(row("Patching", payload.patching));
  }

  if (payload.version) {
    columns.push(row("Version", payload.version));
  }

  if (payload.publisher) {
    columns.push(row("Publisher", payload.publisher.join(", ")));
  }

  if (payload.createdTime) {
    columns.push(row("Release Date", payload.createdTime));
  }
  
  if (isGame) {
    const url = `https://datacrystal.romhacking.net/wiki/${payload.title}`;
    const hyperLink = <a target="_blank" href={url} rel="noreferrer">Goto</a>;
    columns.push(row("DataCrystal", hyperLink));
  }

  if (payload.legacyId) {
    const legacyId = payload.legacyId;
    columns.push(row("Legacy Id", <a target="_blank" href={`https://www.romhacking.net/${legacyId}`} rel="noreferrer">{legacyId}</a>));
  }

  if (payload.releaseDate) {
    columns.push(row("Release Date", <Time time={payload.releaseDate} />));
  }

  if (payload.modifiedDate) {
    columns.push(row("Modified Date", <Time time={payload.modifiedDate} />));
  }

  if (payload.downloadCount) {
    columns.push(row("Downloads", payload.downloadCount.toLocaleString()));
  }

  if (columns.length === 0){
    return;
  }

  return (
    <Card className={margin} title={payload && payload.title}>
      <div className="flex justify-center items-center mb-2">
        {payload.thumbnailUrl ? <img src={payload.thumbnailUrl} alt="" />: null}
        {payload.screenshotUrl ? <img src={payload.screenshotUrl} alt="" />: null}
      </div>

      <Table>
        <thead className="invisible">
          <tr>
            <td className="" />
            <td className="" />
          </tr>
        </thead>
        <tbody>{columns}</tbody>
      </Table>
    </Card>
  );
}

Bob.propTypes = {
  payload: PropTypes.any,
  isGame: PropTypes.bool,
};
