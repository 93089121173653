/** LIBS */
import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Markdown from "react-markdown";

/* CUSTOMS */
import Paginator from "components/paginator";
import Card from "components/card";
import { fetchPost } from "lib/fetch";
import Title from "components/title";
import Loader from "components/loader";
import { SortableTable } from "components/sortableTable";
import SeoHeader from "components/seoHeader";
import { Link } from "react-router-dom";
import Bob from "../bob";

/* CONSTANTS */
import { pageSize } from "constants/models";
import {
  GameBackendPath,
  GetBackendPostfix,
  ContentBackendPath,
  SearchBackendPostfix,
  MetadataBackendPostfix,
} from "constants/routing/backend";
import { removeMarkdownFormatting } from "lib/strings";
import { requestFormatFilter } from "lib/elastic";
import HistoryCard from "pages/history";
import {
  AuthorFrontendPath,
  ContentFrontendPath,
  DatabaseFrontendPath,
  DeletePostfix,
  EntryPostfix,
  GamesFrontendPath,
  SubmissionFrontendPath,
} from "constants/routing/frontend";
import Button from "components/button";

/* SERVICES */
import { UserContext } from "context/user";
import QueueManageButtons from "../queueManageButton";
import DeletionNoticeCard from "../deletionNotice";

export default function HackEntry() {
  const { id } = useParams();

  const margin = "ml-1 mb-3";

  const { userContext } = useContext(UserContext);
  const { username /*, permissions*/ } = userContext;

  const [payloadGame, setPayloadGame] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [contentPayload, setPayload] = useState(null);
  const [page, setPage] = useState(0);
  const [sortField, setSortField] = useState("gameTitle");
  const [sortDirection, setSortDirection] = useState("asc");
  const [metadata, setMetadata] = useState(null);

  useEffect(() => {
    gameGetRequest();
  }, []);

  useEffect(() => {
    contentRequest();
  }, [page, sortField, sortDirection, payloadGame]);

  const contentRequest = () => {
    if (!payloadGame) {
      return;
    }

    setIsLoading(true);

    let matchFilter = requestFormatFilter();

    matchFilter.should.push({
      field: "gameId",
      type: "contains",
      argument: id,
    });

    const body = {
      page: page,
      sortField: sortField,
      sortDirection: sortDirection,
      filters: matchFilter,
    };

    fetchPost(ContentBackendPath + MetadataBackendPostfix, null)
      .then((resp) => {
        setMetadata(resp);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });

    fetchPost(ContentBackendPath + SearchBackendPostfix, body)
      .then((resp) => {
        setPayload(resp);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const gameGetRequest = () => {
    setIsLoading(true);

    const body = {
      id: id,
    };

    fetchPost(GameBackendPath + GetBackendPostfix, body)
      .then((resp) => {
        setPayloadGame(resp);
      })
      .catch((resp) => {
        toast.error(resp.message);
        setPayloadGame(null);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const gameDetails = () => {
    if (!payloadGame || !payloadGame.description) {
      return;
    }

    return (
      <Card className={margin} title={"Game Description / Review"}>
        <div className="max-h-80 overflow-y-auto">
          <Markdown className="text-left mr-3">{payloadGame && payloadGame.description}</Markdown>
        </div>
      </Card>
    );
  };

  const contentCard = () => {
    if (!contentPayload) {
      return;
    }

    const columns = [
      {
        label: "Title",
        idField: "id",
        field: "title",
        width: "",
        dataType: "link",
        to: DatabaseFrontendPath + ContentFrontendPath + EntryPostfix,
      },
      {
        label: "Released By",
        idField: "authorId",
        field: "authorName",
        width: "",
        dataType: "link",
        to: DatabaseFrontendPath + AuthorFrontendPath + EntryPostfix,
      },
      {
        label: "Platform",
        field: "platform",
        width: "",
        dataType: "write",
      },
      {
        label: "Tags",
        field: "tags",
        width: "col-span-12",
        dataType: "write",
      },
    ];

    const paginationElement = () => {
      return (
        <Paginator
          page={page}
          setPage={setPage}
          currentPageSize={contentPayload.entries && contentPayload.entries.length}
          pageWindowSize={pageSize}
          totalEntries={contentPayload && contentPayload.total}
        />
      );
    };

    if (contentPayload.total === 0) {
      return <></>;
    }

    return (
      <>
        <div className="mb-3">
          <Title>Content</Title>
          <div className="flex items-center justify-end">{paginationElement()}</div>
          <SortableTable
            data={contentPayload && contentPayload.entries}
            metadataColumn={metadata && metadata.columns}
            columns={columns}
            setSortField={setSortField}
            setSortDirection={setSortDirection}
            sortField={sortField}
            sortDirection={sortDirection}
          />
        </div>
      </>
    );
  };

  const cardClass = "lg:w-1/2 w-full";

  return (
    <>
      <SeoHeader pageTitle={"Game"} pageSectionTitle={payloadGame && payloadGame.title} />

      {payloadGame ? (
        <SeoHeader
          pageTitle={"Game"}
          pageSectionTitle={payloadGame.title}
          description={removeMarkdownFormatting(payloadGame.description)}
        />
      ) : null}

      <Title>Game Entry</Title>

      {payloadGame ? (
        <QueueManageButtons id={id} frontendPath={GamesFrontendPath} entityType={"game"} isDeleted={payloadGame.isDeleted} />
      ) : null}

      <Loader isLoading={isLoading} />
      <div className="lg:flex">
        <div className={`${cardClass} mr-2`}>
          {<Bob payload={payloadGame} isGame={true} />}
          {<HistoryCard payload={payloadGame} />}
        </div>
        <div className={`${cardClass} ${margin}`}>
          {<DeletionNoticeCard payload={payloadGame}/>}
          {gameDetails()}
        </div>
      </div>
      <div className="">{contentCard()}</div>
    </>
  );
}
