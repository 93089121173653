export function downloadFile(url, filename) {
  fetch(url)
    .then(response => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.blob();
    })
    .then(blob => {
      const a = document.createElement("a");
      a.href = URL.createObjectURL(blob);
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    })
    .catch(error => console.error("There was a problem with the fetch operation:", error));
}
  
export async function downloadFileToArrayBuffer(url) {
  try {
    if (!url){
      throw new Error("Url cannot be empty.");
    }

    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const arrayBuffer = await response.arrayBuffer();
    return arrayBuffer;
  } catch (error) {
    throw new Error("Error downloading the file:", error);
  }
}

export function extractBucketAndKey(url) {
  if (!url){
    return { bucket: null, key: null, keyPostfix: null };
  }

  let bucket = "";
  let key = "";
  let keyPostfix = "";

  if (url.startsWith("s3://")) {
    // Handle s3:// URL
    const parts = url.substring(5).split("/");
    bucket = parts[0];
    key = parts.slice(1).join("/");
    keyPostfix = parts.slice(-1)[0];
  } else {
    // Handle HTTP(S) URL
    try {
      const { pathname } = new URL(url);
      const parts = pathname.substring(1).split("/");
      bucket = parts[0];
      key = parts.slice(1).join("/");
      keyPostfix = parts.slice(-1)[0];
    } catch (e) {
      throw new Error("Invalid URL:", url);
    }
  }

  key = decodeURIComponent(key);
  keyPostfix = decodeURIComponent(keyPostfix);

  return { bucket, key, keyPostfix };
}