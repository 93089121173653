/** LIBS */
import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Markdown from "react-markdown";
import Title from "components/title";

/* CUSTOMS */
import Paginator from "components/paginator";
import Loader from "components/loader";
import Card from "components/card";
import { fetchPost } from "lib/fetch";
import { SortableTable } from "components/sortableTable";
import SeoHeader from "components/seoHeader";

/* CONSTANTS */
import { pageSize } from "constants/models";
import {
  AuthorBackendPath,
  GetBackendPostfix,
  SearchBackendPostfix,
  MetadataBackendPostfix,
  ContentBackendPath,
} from "constants/routing/backend";
import { filterOperatorShould, requestFormatFilter } from "lib/elastic";
import { removeMarkdownFormatting } from "lib/strings";
import HistoryCard from "pages/history";
import {
  AuthorFrontendPath,
  ContentFrontendPath,
  DatabaseFrontendPath,
  DeletePostfix,
  EntryPostfix,
  GamesFrontendPath,
  SubmissionFrontendPath,
} from "constants/routing/frontend";
import Tags from "../tags";
import Button from "components/button/index";

/* SERVICES */
import { UserContext } from "context/user";
import QueueManageButtons from "../queueManageButton";
import DeletionNoticeCard from "../deletionNotice";

export default function AuthorEntry() {
  const [payloadAuthor, setPayloadAuthor] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { id } = useParams();

  const margin = "ml-1 mb-3";

  const { userContext } = useContext(UserContext);
  const { username /*, permissions*/ } = userContext;

  useEffect(() => {
    authorGetRequest();
  }, [id]);

  const authorGetRequest = () => {
    setIsLoading(true);

    const body = {
      id: id,
    };

    fetchPost(AuthorBackendPath + GetBackendPostfix, body)
      .then((resp) => {
        setPayloadAuthor(resp);
      })
      .catch((resp) => {
        toast.error(resp.message);
        setPayloadAuthor(null);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const authorDescription = () => {
    var description = payloadAuthor.description;

    if (!description) {
      description = "*No description*";
    }

    return (
      <Card className={margin} title={"Author Description"}>
        <div className="max-h-80 overflow-y-auto">
          <Markdown className="text-left mr-3">{description}</Markdown>
        </div>
      </Card>
    );
  };

  const authorAbout = () => {
    const linkList = () => {
      if (!payloadAuthor.externalLinks || Object.keys(payloadAuthor.externalLinks).length === 0) {
        return;
      }

      return (
        <div>
          <h3 className="text-left">Links</h3>
          <ol>
            {payloadAuthor.externalLinks.map((x, i) => {
              return (
                <li key={`externalLinks-${i}`} className="text-left">
                  <Link className="text-sm underline" target="_blank" to={x}>
                    {x}
                  </Link>
                </li>
              );
            })}
          </ol>
        </div>
      );
    };

    const aliasList = () => {
      if (!payloadAuthor.aliases || Object.keys(payloadAuthor.aliases).length === 0) {
        return;
      }

      return (
        <div>
          <h3 className="text-left">Aliases</h3>
          <ol>
            {payloadAuthor.aliases.map((x, i) => {
              return (
                <li key={`aliases-${i}`} className="text-left">
                  {x}
                </li>
              );
            })}
          </ol>
        </div>
      );
    };

    const legacyId = () => {
      if (!payloadAuthor.legacyId) {
        return;
      }

      const legacyId = payloadAuthor.legacyId;
      return (
        <div>
          <h3 className="text-left">Legacy Id</h3>
          <ol>
            <li className="text-left">
              <a href={`https://www.romhacking.net/${legacyId}`}>{legacyId}</a>
            </li>
          </ol>
        </div>
      );
    };

    const profileImage = () => {
      if (!payloadAuthor.thumbnailUrl) {
        return;
      }

      return (

        <div className="flex items-center justify-center">
          <img src={payloadAuthor.thumbnailUrl} alt=""/>
        </div>
      );
    };

    return (
      <Card className={margin} title={"Details"}>
        <div>
          {profileImage()}
          {aliasList()}
          {linkList()}
          {<Tags payload={payloadAuthor} />}
          {legacyId()}
        </div>
      </Card>
    );
  };

  const [hackPayload, setPayload] = useState(null);
  const [page, setPage] = useState(0);
  const [sortField, setSortField] = useState("gameTitle");
  const [sortDirection, setSortDirection] = useState("asc");
  const [metadata, setMetadata] = useState(null);

  useEffect(() => {
    fetchPost(ContentBackendPath + MetadataBackendPostfix, null)
      .then((resp) => {
        setMetadata(resp);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    hackRequest();
  }, [page, sortField, sortDirection, payloadAuthor]);

  const hackRequest = () => {
    if (!payloadAuthor) {
      return;
    }

    setIsLoading(true);

    let matchFilter = requestFormatFilter([
      {
        field: "authorId",
        operator: filterOperatorShould,
        value: id,
      },
      {
        field: "credits.creditId",
        operator: filterOperatorShould,
        value: id,
      },
    ]);

    const body = {
      page: page,
      sortField: sortField,
      sortDirection: sortDirection,
      filters: matchFilter,
    };

    fetchPost(ContentBackendPath + SearchBackendPostfix, body)
      .then((resp) => {
        setPayload(resp);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const contributionCard = () => {
    if (!hackPayload) {
      return;
    }

    const columns = [
      {
        label: "Original Game",
        idField: "gameId",
        field: "gameTitle",
        width: "w-20",
        dataType: "link",
        to: DatabaseFrontendPath + GamesFrontendPath + EntryPostfix,
      },
      {
        label: "Title",
        idField: "id",
        field: "title",
        width: "w-20",
        dataType: "link",
        to: DatabaseFrontendPath + ContentFrontendPath + EntryPostfix,
      },
      {
        label: "Released By",
        idField: "authorId",
        field: "authorName",
        width: "w-10 max-lg:hidden",
        dataType: "link",
        to: DatabaseFrontendPath + AuthorFrontendPath + EntryPostfix,
      },
      {
        label: "Platform",
        field: "platform",
        width: "w-10",
        dataType: "write",
      },
      {
        label: "Tags",
        field: "tags",
        width: "w-20",
        dataType: "write",
      },
    ];

    const paginationElement = () => {
      return (
        <Paginator
          page={page}
          setPage={setPage}
          currentPageSize={hackPayload.entries && hackPayload.entries.length}
          pageWindowSize={pageSize}
          totalEntries={hackPayload && hackPayload.total}
        />
      );
    };

    if (hackPayload.total === 0) {
      return <></>;
    }

    return (
      <>
        <Title>Contributions</Title>
        <div className="flex items-center justify-end">{paginationElement()}</div>
        <SortableTable
          data={hackPayload && hackPayload.entries}
          metadataColumn={metadata && metadata.columns}
          columns={columns}
          setSortField={setSortField}
          setSortDirection={setSortDirection}
          sortField={sortField}
          sortDirection={sortDirection}
        />
      </>
    );
  };

  const cardClass = "lg:w-1/2 w-full";

  if (!payloadAuthor) {
    return;
  }

  var authorName = payloadAuthor.title;

  if (payloadAuthor.isGroup) {
    authorName += " (Group)";
  }

  return (
    <>
      {payloadAuthor ? (
        <SeoHeader
          pageTitle={"Author"}
          pageSectionTitle={payloadAuthor.title}
          description={removeMarkdownFormatting(payloadAuthor.description)}
        />
      ) : null}

      <Loader isLoading={isLoading} />

      <Title>{authorName}</Title>

      {payloadAuthor ? (
        <QueueManageButtons id={id} frontendPath={ContentFrontendPath} entityType={"author"} isDeleted={payloadAuthor.isDeleted} />
      ) : null}

      <div className="lg:flex">
        <div className={`${cardClass} mr-2`}>
          {authorAbout()}
          {<HistoryCard payload={payloadAuthor} />}
        </div>
        <div className={`${cardClass} ${margin}`}>
          {<DeletionNoticeCard payload={payloadAuthor}/>}
          {authorDescription()}</div>
      </div>

      <div className={`$w-full col-span-6 ${margin}`}>{contributionCard()}</div>
    </>
  );
}
