/* LIBS */
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { IsSortableField } from "lib/sortableField";

/* CUSTOMS */
import Time from "components/time";
import Pill from "components/pill";
import Button from "components/button";
import { Table } from "components/table";

/* ICONS */
import { IoIosArrowDown as SortDownIcon, IoIosArrowUp  as SortUpIcon} from "react-icons/io";
import { MdSort } from "react-icons/md";
import { BsCheckLg as CheckMarkIcon, BsXLg as XMarkIcon } from "react-icons/bs";

export const SortableTable = ({
  metadataColumn,
  columns,
  data,

  sortField,
  sortDirection,

  setSortField,
  setSortDirection,
}) => {
  const headerColClass = "px-4 py-3 text-left tracking-wider";
  const rowColClass = "px-4 py-3 text-left";

  const extractFromObjectWithPath = (path, obj) => {
    const pathFull = path.split(".");
    let extractedValues = [];

    const extractFromObjectWithPathRecursive = (path, o) => {
      let s;

      if (Array.isArray(path)) {
        s = path[0];
      } else {
        s = path;
      }

      // If path is empty, assume we are reading an object of values.
      if (s === undefined || s === null) {
        extractedValues = [...extractedValues, o];
        return;
      }

      const objVal = o[s];

      if (objVal === null){
        return null;
      }

      switch (typeof objVal) {
      case "object":
        if (Array.isArray(objVal)) {
          extractedValues = objVal.join(", ");
        } else {
          extractedValues = [...extractedValues, objVal[path[path.length - 1]]];
        }
        break;
      case "string":
      case "number":
      case "boolean":
        extractedValues = [...extractedValues, objVal];
        break;
      default:
        extractFromObjectWithPathRecursive(path.slice(1), objVal);
        break;
      }
    };

    extractFromObjectWithPathRecursive(pathFull, obj);

    if (Array.isArray(extractedValues) && extractedValues.length === 1) {
      extractedValues = extractedValues[0];
    }

    return extractedValues;
  };

  return (
    <Table>
      <thead>
        <tr>
          {columns &&
            columns.map((c, i) => {
              let onClick = () => {};
              let content = c.label;
              const field = c.field;

              let isSortable = false;

              if (
                metadataColumn &&
                Object.prototype.hasOwnProperty.call(metadataColumn, c.field)
              ) {
                isSortable = IsSortableField(metadataColumn[c.field].type);
              }

              if (isSortable) {
                const className = "ml-2";

                let icon = null;

                if (
                  sortField &&
                  sortDirection &&
                  setSortDirection &&
                  setSortField
                ) {
                  if (sortField === field) {
                    if (sortDirection === "asc") {
                      icon = <SortUpIcon className={className} />;
                      onClick = () => {
                        setSortDirection("desc");
                        setSortField(field);
                      };
                    } else {
                      icon = <SortDownIcon className={className} />;
                      onClick = () => {
                        setSortDirection("asc");
                        setSortField(field);
                      };
                    }
                  } else {
                    icon = <MdSort className={className} />;
                    onClick = () => {
                      setSortDirection("asc");
                      setSortField(field);
                    };
                  }
                }

                content = (
                  <div
                    onClick={onClick}
                    className={`flex items-center ${
                      isSortable && "cursor-pointer"
                    }`}
                  >
                    {content}
                    {icon}
                  </div>
                );
              }

              return (
                <th
                  key={`header-${i}`}
                  className={`${headerColClass} ${c.width} `}
                >
                  {content}
                </th>
              );
            })}
        </tr>
      </thead>
      <tbody>
        {data && data.map((d, dataI) => {
          return (
            <tr key={`Tbody-${dataI}`}>
              {columns &&
                  columns.map((c, cI) => {
                    let className = `${c.width}`;
                    let content = null;
                    const fieldValue = extractFromObjectWithPath(c.field, d);
                    let onClick = c.onClick ?? (() => {});
                    var to = c.to;
                    let hoverText = null;

                    // Auto-linebreaks and word wraps long strings
                    let truncationStyle = "break-words";

                    switch (c.dataType) {
                    case "write":
                      if (Array.isArray(fieldValue)) {
                        content = fieldValue.join(", ");
                        hoverText = content;
                      } else if (typeof fieldValue === "boolean") {
                        let text = fieldValue ? (
                          <CheckMarkIcon />
                        ) : (
                          <XMarkIcon />
                        );
                        let color = fieldValue ? "green" : "red";
                        content = <Pill text={text} color={color} />;
                      } else {
                        content = fieldValue;
                        hoverText = content;
                      }

                      break;
                    case "dateTime":
                      content = <Time time={fieldValue} />;
                      break;
                    case "enableChip":
                      var text = fieldValue ? "Active" : "Disabled";
                      var color = fieldValue ? "green" : "red";
                      content = <Pill text={text} color={color} />;
                      hoverText = text;
                      break;
                    case "link":
                    {
                      const id = extractFromObjectWithPath(c.idField, d);

                      if (typeof to === "function") {
                        to = to(d);
                      }

                      const url = to + id;

                      content = (
                        <Link
                          className="text-sm underline"
                          to={url}
                        >
                          {fieldValue}
                        </Link>
                      );

                      hoverText = fieldValue;
                      break;
                    }
                    case "button":
                      content = (
                        <Button
                          className="rounded-md"
                          secondary={c.buttonSecondary}
                          onClick={() => onClick(d, dataI)}
                        >
                          {c.buttonText ?? c.label}
                        </Button>
                      );
                      break;
                    case "lookup":
                      content = c.lookupFunc(fieldValue);
                      hoverText = content;
                      break;
                    default:
                      console.error(`Invalid datatype: ${c.dataType}`);
                    }

                    return (
                      <td
                        key={`td-${cI}`}
                        className={`${rowColClass} ${truncationStyle} ${d.width} ${
                          className || ""
                        }`}
                        title={hoverText}
                      >
                        {content}
                      </td>
                    );
                  })}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

SortableTable.propTypes = {
  metadataColumn: PropTypes.any,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      field: PropTypes.string.isRequired,
      width: PropTypes.string.isRequired,
      dataType: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      lookupFunc: PropTypes.func,
      to: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
      ]),

      idField: PropTypes.string,

      buttonSecondary: PropTypes.bool,
      buttonText: PropTypes.any,
    })
  ).isRequired,
  data: PropTypes.any,
  sortField: PropTypes.string,
  sortDirection: PropTypes.string,

  setSortField: PropTypes.func,
  setSortDirection: PropTypes.func,
};
