/* LIBS */
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

/* CUSTOMS */
import Paginator from "components/paginator";
import { pageSize } from "constants/models";
import { fetchPost } from "lib/fetch";
import Title from "components/title";
import { SortableTable } from "components/sortableTable";
import Loader from "components/loader";
import AdvancedFilterCard from "components/advancedFilterCard/index";

/* CONSTANTS */
import {
  HistoryMetricBackendPath,
  SearchBackendPostfix,
  MetadataBackendPostfix,
} from "constants/routing/backend";
import { requestFormatFilter } from "lib/elastic";

export default function HistoryMetricGridView() {
  const [payload, setPayload] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const [page, setPage] = useState(0);
  const [sortField, setSortField] = useState("history.time");
  const [sortDirection, setSortDirection] = useState("desc");
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState([]);
  const [queryString, setQueryString] = useState("");

  const columns = [
    {
      label: "Time",
      field: "history.time",
      width: "w-10 max-md:hidden",
      dataType: "dateTime",
    },
    {
      label: "Entity ",
      field: "entityType",
      width: "w-5",
      dataType: "write",
    },
    {
      label: "Title",
      idField: "entityId",
      field: "entityTitle",
      width: "w-20",
      dataType: "link",
      to: (data) => {
        return `/database/${data.entityType}/entry/`;
      },
    },
    {
      label: "Username",
      field: "history.username",
      width: "w-10",
      dataType: "write",
    },
    {
      label: "Message",
      field: "history.message",
      width: "w-40",
      dataType: "write",
    },
  ];
  
  useEffect(() => {
    fetchPost(HistoryMetricBackendPath + MetadataBackendPostfix, null)
      .then((resp) => {
        setMetadata(resp);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    setPage(0);
  }, [sortField, sortDirection, filters]);

  useEffect(() => {
    hackRequest();
  }, [page, sortField, sortDirection, filters]);

  const hackRequest = () => {
    setIsLoading(true);

    let matchFilter = requestFormatFilter(filters);

    const body = {
      page: page,
      sortField: sortField,
      sortDirection: sortDirection,
      filters: matchFilter,
      queryString: queryString,
    };

    fetchPost(HistoryMetricBackendPath + SearchBackendPostfix, body)
      .then((resp) => {
        setPayload(resp);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const paginationElement = (autoScroll, allowInputChange) => {
    if (!payload) {
      return;
    }

    return (
      <Paginator
        page={page}
        setPage={setPage}
        currentPageSize={payload.entries && payload.entries.length}
        pageWindowSize={pageSize}
        totalEntries={payload && payload.total}
        enableAutoScroll={autoScroll}
        allowInputChange={allowInputChange}
      />
    );
  };

  return (
    <>
      <Title>Changes</Title>
      <Loader isLoading={isLoading} />

      <div className="lg:grid lg:grid-cols-12 gap-3">
        <div className="col-span-4">
          <AdvancedFilterCard
            filters={filters}
            setFilters={setFilters}
            setQueryString={setQueryString}
            searchUri={HistoryMetricBackendPath}
            onClick={hackRequest}
            metadata={metadata}
          />
        </div>
        <div className="col-span-8">
          <div className="flex items-center justify-end">
            {paginationElement(false, true)}
          </div>
          <SortableTable
            data={payload && payload.entries}
            metadataColumn={metadata && metadata.columns}
            columns={columns}
            setSortField={setSortField}
            setSortDirection={setSortDirection}
            sortField={sortField}
            sortDirection={sortDirection}
          />
          <div className="flex items-center justify-end">
            {paginationElement(true, false)}
          </div>
        </div>
      </div>
      <Loader isLoading={isLoading} />
    </>
  );
}
