/* LIBS */
import React, { useState } from "react";
import PropTypes from "prop-types";

const ImageHover = ({src, hoverSrc, alt, className, onClick}) => {
  const [isHovered, setIsHovered] = useState(false);

  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    if (onClick) {
      onClick();
    }

    setIsClicked(true);

    setTimeout(() => {
      setIsClicked(false);
    }, 100);
  };

  return (
    <div
      onClick={handleClick}
      className={`select-none transition-transform duration-100 transform ${isClicked ? "-rotate-0" : "hover:-rotate-6"}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onTouchStart={() => setIsHovered(true)}
      onTouchEnd={() => setIsHovered(false)}
    >
      {isHovered || <img
        src={src}
        alt={alt}
        className={`${className}`}
      />}
      {!isHovered || <img
        src={hoverSrc}
        alt={alt}
        className={`${className}`}
      />}
    </div>
  );
};

ImageHover.propTypes = {
  src: PropTypes.any,
  hoverSrc: PropTypes.any,
  alt: PropTypes.string,
  className: PropTypes.string, 
  onClick: PropTypes.func, 
};

export default ImageHover;