import React, { useContext } from "react";
import { ThemeContext } from "context/themeProvider";
import PropTypes from "prop-types";
import { PacmanLoader } from "react-spinners";

export default function Loader({ isLoading }) {

  const { theme } = useContext(ThemeContext);

  return (
    <>
      {/*
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-50">
      <div className="w-16 h-16 border-t-4 border-blue-500 border-solid rounded-full animate-spin"></div>
    </div>
    */}
      {isLoading && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <PacmanLoader
            color={theme === "light" ? "var(--menuColor)": "white"}
          />
        </div>
      )}
    </>
  );
}

Loader.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};
