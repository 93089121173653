/* LIBS */
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Markdown from "react-markdown";
import Button from "components/button";
import { useLocation, useNavigate } from "react-router-dom";

/* CUSTOMS */
import MultiSelectAutocomplete2 from "components/multiSelectAutocomplete2";
import TextArea from "components/textArea";
import TextBox from "components/textBox";
import Card from "components/card";
import Heading from "components/heading";
import Title from "components/title";
import { fetchPost } from "lib/fetch";
import Loader from "components/loader";
import DropzoneComponent, { FileInformation } from "components/dropZone";
import { downloadFileToArrayBuffer, extractBucketAndKey } from "lib/downloadUrl";

/* CONSTANTS */
import {
  GetBackendPostfix,
  FieldValuesBackendPostfix,
  QueueBackendPostfix,
  CreateBackendPostfix,
  UpdateBackendPostfix,
  GameBackendPath,
  QueueBackendPath,
} from "constants/routing/backend";

import { EntryPostfix, ListPostfix, SubmissionQueueFrontendPath } from "constants/routing/frontend";
import { allowedImageType } from "constants/models/index";

export default function GameSubmission() {
  const [isLoading, setIsLoading] = useState(false);

  const [entityId, setEntityId] = useState("");

  const [contentTitle, setContentTitle] = useState("");
  const [altTitle, setAltTitle] = useState("");
  const [description, setDescription] = useState("");

  const [tags, setTags] = useState([]);
  const [publisher, setPublisher] = useState("");
  const [platform, setPlatform] = useState("");
  const [fileTitleScreen, setFileTitleScreen] = useState([]);

  const [historyComment, setHistoryComment] = useState("");

  // Function to parse query parameters
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queueId = queryParams.get("queueId");
  const entityQueryId = queryParams.get("entityId");

  const navigate = useNavigate();

  useEffect(() => {
    if (entityQueryId && queueId) {
      toast.error("Cannot edit both queue and game");
      return;
    }

    if (entityQueryId) {
      contentRequest(entityQueryId);
    }

    if (queueId) {
      queueRequest(queueId);
    }
  }, []);

  useEffect(() => {
    setEntityId(entityQueryId);
  }, [entityQueryId]);

  const markdownWidth = "md:col-span-3 col-span-6";

  const validationCheck = () => {
    var pass = true;

    if (tags.length === 0) {
      toast.error("Must select at least one tag");
      pass = false;
    }

    if (contentTitle === "") {
      toast.error("Must enter a title.");
      pass = false;
    }

    if (Object.keys(fileTitleScreen).length > 1) {
      toast.error("Cannot have more than one thumbnail.");
      pass = false;
    }

    return pass;
  };

  const queueSubmitRequest = () => {
    if (!validationCheck()) {
      return;
    }

    const isUpdate = queueId !== null;

    const params = {
      entityId: entityId,
      
      queueId: queueId,

      title: contentTitle,
      alternateTitle: altTitle,
      publisher: publisher,
      tags: tags,
      description: description,
      thumbnail: fileTitleScreen.length > 0 ? fileTitleScreen[0].ConvertToRequest() : null,
      historyComment: historyComment,
      platform: platform
    };

    const postfix = isUpdate ? UpdateBackendPostfix : CreateBackendPostfix;

    setIsLoading(true);
    fetchPost(GameBackendPath + QueueBackendPostfix + postfix, params)
      .then(() => {
        toast.success(`Entry ${isUpdate ? "updated" : "created"}.`);
        if (queueId) {
          navigate(SubmissionQueueFrontendPath + EntryPostfix + queueId);
        } else {
          navigate(SubmissionQueueFrontendPath + ListPostfix);
        }
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const contentRequest = (id) => {
    const body = {
      id: id,
    };

    setIsLoading(true);

    fetchPost(GameBackendPath + GetBackendPostfix, body)
      .then((d) => {
        setHooks(d);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const queueRequest = (id) => {
    const body = {
      id: id,
    };

    setIsLoading(true);

    fetchPost(QueueBackendPath + GetBackendPostfix, body)
      .then((e) => {

        const d = JSON.parse(e.queueEntry.data);
       
        setHooks(d);
        setEntityId(e.queueEntry.entityId);
        setHistoryComment(e.queueEntry.historyComment);
      })
      .catch((resp) => {
        toast.error(resp.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const setHooks = (d) => {
    setContentTitle(d.title);
    setAltTitle(d.alternateTitle);
    setDescription(d.description);
    setTags(d.tags);
    setPublisher(d.publisher);
    setPlatform(d.platform);

    if (d.thumbnailUrl) {
      downloadFileToArrayBuffer(d.thumbnailUrl)
        .then((bytes) => {
          const { key, keyPostfix } = extractBucketAndKey(d.thumbnailUrl);
          setFileTitleScreen([new FileInformation(key, bytes, keyPostfix, false)]);
        })
        .catch((resp) => {
          toast.error(resp.message);
        });
    }
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      <Title className={"col-span-6"}>{queueId || entityQueryId ? "Edit Game" : "Submit Game"}</Title>
      <Card className="m-8" title={"General Information"}>
        <div className="grid grid-cols-6 gap-4 text-start">
          <TextBox
            value={contentTitle}
            id={"content-title-textBox"}
            className={"max-lg:col-span-6 md:col-span-2 w-full"}
            placeholder={"Content Title"}
            onChange={setContentTitle}
          />
          <TextBox
            value={altTitle}
            id={"content-alt-textBox"}
            className={"max-lg:col-span-6 md:col-span-2 w-full"}
            placeholder={"Alternate Title"}
            onChange={setAltTitle}
          />

          <MultiSelectAutocomplete2
            className="max-lg:col-span-6 md:col-span-2"
            placeholder="Publisher"
            field="publisher"
            searchUri={GameBackendPath}
            searchPostfix={FieldValuesBackendPostfix}
            label={publisher}
            isMulti={true}
            setLabel={setPublisher}
            isCreatable={true}
          />

          <MultiSelectAutocomplete2
            className="max-lg:col-span-6 md:col-span-3"
            placeholder="Tags"
            field="tags"
            searchUri={GameBackendPath}
            searchPostfix={FieldValuesBackendPostfix}
            label={tags}
            isMulti={true}
            setLabel={setTags}
          />

          <MultiSelectAutocomplete2
            className="max-lg:col-span-6 md:col-span-3"
            placeholder="Platforms"
            field="platform"
            searchUri={GameBackendPath}
            searchPostfix={FieldValuesBackendPostfix}
            label={platform}
            isMulti={false}
            setLabel={setPlatform}
          />


        </div>
      </Card>

      <Card className="m-8" title={"Title Screen"}>
        <div className="grid grid-cols-6 gap-4 text-start">
          <div className={markdownWidth}>
            <Heading>Title Screen</Heading>
            <DropzoneComponent
              files={fileTitleScreen}
              setFiles={setFileTitleScreen}
              allowedFileTypes={allowedImageType}
              allowMultiple={false}
            />
          </div>
        </div>
      </Card>

      <Card className="m-8" title={"Description"}>
        <div className="grid grid-cols-6 gap-4 text-start">
          <div className={markdownWidth}>
            <Heading>Markdown</Heading>
            <TextArea
              noResize={true}
              wrapperClassName="w-full"
              className={"h-80"}
              value={description}
              onChange={(value) => {
                setDescription(value);
              }}
            />
          </div>
          <div className={markdownWidth}>
            <Heading>Preview</Heading>
            <div className="col-span-3 overflow-scroll h-80">
              <Markdown className="w-full">{description}</Markdown>
            </div>
          </div>
        </div>
      </Card>

      <Card className="m-8" title={"History Comment"}>
        <div className="w-full text-start">
          <TextArea
            placeholder="Comment"
            noResize={true}
            wrapperClassName="w-full"
            className={"h-20"}
            value={historyComment}
            onChange={setHistoryComment}
          />
        </div>
      </Card>

      <div className="flex justify-end mr-5">
        <Button className={"mr-3 ml-3 mb-3 mt-3 overflow-hidden rounded-b-md rounded-t-md"} onClick={queueSubmitRequest}>
          Submit
        </Button>
      </div>
    </>
  );
}
