import React from "react";
import PropTypes from "prop-types";

export default function VideoPlayer({ videoUrl, className, ...otherProps }) {
  if (!videoUrl){
    return;
  }

  if (!isValidURL(videoUrl)){
    return <i>Only YouTube embeds are allows for videos</i>;
  }


  return (
    <iframe
      {...otherProps}
      className={`${className || ""}`}
      src={videoUrl}
      allow='encrypted-media'
      allowfullscreen
      title='video'
    />
  );
}

function isValidURL(url) {
  // Regular expression for validating URLs
  const urlPattern = /^https:\/\/(?:www\.)?youtube\.com\/embed\/[a-zA-Z0-9_-]+$/;

  return urlPattern.test(url);
}

VideoPlayer.propTypes = {
  videoUrl: PropTypes.string,
  className: PropTypes.string,
};
