import React from "react";
import Moment from "react-moment";
import { EpochSecondsToEpochMilliseconds } from "lib/time";
import PropTypes from "prop-types";

export default function Time({ time, className, fromNow }) {
  return (
    <div className={className}>
      {time !== 0 ? (
        <Moment
          date={EpochSecondsToEpochMilliseconds(time)}
          format={!fromNow ? "YYYY MMM DD" : null}
          utc={true}
          fromNow={fromNow}
        />
      ) : (
        "N/A"
      )}
    </div>
  );
}

Time.propTypes = {
  time: PropTypes.number.isRequired,
  className: PropTypes.string,
  fromNow: PropTypes.bool
};
