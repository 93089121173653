/* LIBS */
import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import Moment from "react-moment";

/* CUSTOMS */
import { EpochSecondsToEpochMilliseconds } from "lib/time";

// https://www.geeksforgeeks.org/how-to-create-a-countdown-timer-using-reactjs/
export default function Timer(props) {
  const [timer, setTimer] = useState("");
  const { startTime } = props;

  const secondIncrements = 1;
  const ref = useRef(null);

  useEffect(() => {
    clearTimer();
  }, [startTime]);

  const clearTimer = () => {
    let currentTime = startTime;
    setTimer(PrintAgo(currentTime));

    if (ref.current) {
      clearInterval(ref.current);
    }

    if (startTime) {
      const id = setInterval(() => {
        currentTime += secondIncrements;
        setTimer(PrintAgo(currentTime));
      }, secondIncrements * 1000);

      ref.current = id;
    }
  };

  return <>{timer}</>;
}

const wayTooLong = 99 * 60 * 60 + 59 * 60 + 59;

export function PrintAgo(time) {
  const start = new Date() - EpochSecondsToEpochMilliseconds(time);

  return (
    <>
      {time && time < wayTooLong ? (
        <Moment date={start} format="hh:mm:ss" durationFromNow />
      ) : (
        "N/A"
      )}
    </>
  );
}

export function PrintTime({seconds}) {
  const s = Math.floor(seconds % 60);
  const m = Math.floor((seconds / 60) % 60);
  const h = Math.floor(seconds / 60 / 60);

  if (seconds && seconds < wayTooLong) {
    return `${String(h).padStart(2, "0")}:${String(m).padStart(2, "0")}:${String(s).padStart(2, "0")}`;
  } else {
    return "N/A";
  }
}

Timer.propTypes = {
  startTime: PropTypes.number,
};
