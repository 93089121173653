import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { UserContextProvider } from "context/user";
import { WebsocketBackendPath } from "constants/routing/backend";
import { WebSocketContextProvider } from "context/webSocket";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    {/*<StrictMode>*/}
    <BrowserRouter>
      <UserContextProvider>
        <WebSocketContextProvider webSocketPath={WebsocketBackendPath}>
          <App />
        </WebSocketContextProvider>
      </UserContextProvider>
    </BrowserRouter>
    {/*</StrictMode>*/}
  </>
);
