import React, { memo } from "react";
import PropTypes from "prop-types";

const TextArea = memo(({
  label,
  className,
  wrapperClassName,
  noResize,
  placeholder,
  id,
  value,
  onChange,
  ...otherProps
}) => {
  return (
    <div className={`relative ${className || ""}`}>
      <textarea
        id={id}

        className={`peer
      p-4 rounded-md bg-textBoxColor 
      opacity-75 outline outline-0
      border-solid w-full
      border-2 border-borderColor
      focus:bg-textBoxFocusColor text-contrastChangeText ${className || ""} ${noResize ? "resize-none" : ""}`}
        placeholder={" "}
        defaultValue={value}
        // value={value} omitted for performance
        onBlur={(e) => {
          return onChange(e.target.value);
        }}
        {...otherProps}
      />
      <label
        htmlFor={id}
        className="select-none absolute text-sm text-contrastChangeText text-textBoxPlaceholderText
      duration-300 transform -translate-y-4 scale-75 top-4 z-2 origin-[0] 
      start-2.5 peer-focus:text-blue-600 
      peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
      peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4
      rtl:peer-focus:left-auto"
      >
        {placeholder}
      </label>
    </div>
  );
});

TextArea.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  noResize: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

TextArea.displayName = "TextArea";

export default TextArea;